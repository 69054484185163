/* 
File: inventory.js
Description: provides main routes for road inventory forms, component of the routes.js
*/
const Regions = () => import('@/pages/RoadNetwork/Regions.vue')
const RegionEditForm = () => import('@/pages/RoadNetwork/RegionEditForm.vue')
const Deu = () => import('@/pages/RoadNetwork/Deu.vue')
const DeuEditForm = () => import('@/pages/RoadNetwork/DeuEditForm.vue')
const Roads = () => import('@/pages/RoadNetwork/Roads.vue')
const RoadEditForm = () => import('@/pages/RoadNetwork/RoadEditForm.vue')
const ImportForm = () => import('@/pages/RoadNetwork/ImportForm.vue')
const Sections = () => import('@/pages/RoadNetwork/Sections.vue')
const SectionEditForm = () => import('@/pages/RoadNetwork/SectionEditForm.vue')
const TrafficSites = () => import('@/pages/RoadNetwork/TrafficSites.vue')
const TrafficSiteEditForm = () => import('@/pages/RoadNetwork/TrafficSiteEditForm.vue')

const InventoryDataMenu = [
  /*
    Regions
  */
  {
    path: 'regions',
    name: 'list_of_regions',
    component: Regions,
  },
  {
    path: 'regions/add',
    name: 'regions_add',
    component: RegionEditForm,
    props: { oper: 'add' }
  },
  {
    path: 'regions/upd/:rid',
    name: 'regions_upd',
    component: RegionEditForm,
    props: { oper: 'upd' }
  },
  
  /*
    DEU
  */
  {
    path: 'deu',
    name: 'list_of_deus',
    component: Deu,
  },
  {
    path: 'deu/add',
    name: 'deu_add',
    component: DeuEditForm,
    props: { oper: 'add' }
  },
  {
    path: 'deu/upd/:deu_id',
    name: 'deu_upd',
    component: DeuEditForm,
    props: { oper: 'upd' }
  },

  /*
    Roads
  */
  {
    path: 'roads',
    name: 'list_of_roads',
    components: { default: Roads },
  },
  {
    path: 'roads/add',
    name: 'road_add',
    component: RoadEditForm,
    props: { oper: 'add' }
  },
  {
    path: 'roads/upd/:rid',
    name: 'road_upd',
    component: RoadEditForm,
    props: { oper: 'upd' }
  },

  /*
     Import (Roads/sections)
  */
  {
    path: 'import',
    name: 'roads_sections_import',
    component: ImportForm,
  },

  /*
     Sections
  */
  {
    path: 'sections',
    name: 'list_of_sections',
    components: { default: Sections },
  },
  {
    path: 'sections/add',
    name: 'Section_add',
    component: SectionEditForm,
    props: { oper: 'add' }
  },
  {
    path: 'sections/upd/:sid',
    name: 'Section_upd',
    component: SectionEditForm,
    props: { oper: 'upd' }
  },

  /*
     Traffic sites
  */
  {
    path: 'traffic',
    name: 'Traffic_sites',
    components: { default: TrafficSites },
  },
  {
    path: 'traffic/add',
    name: 'Traffic_site_add',
    component: TrafficSiteEditForm,
    props: { oper: 'add' }
  },
  {
    path: 'traffic/upd/:tid',
    name: 'Traffic_site_upd',
    component: TrafficSiteEditForm,
    props: { oper: 'upd' }
  },

]

export default InventoryDataMenu
