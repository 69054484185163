/* 
File: index.js
Description: main file containig all the componens
*/
// Old one's
import Vue from 'vue'
import Vuex from 'vuex'
import i18nStore from './i18n'
import Login from './login'
import Groups from './rbac/groups'
import Users from './rbac/users'
import Bridges from './bridges'
import Dashboard from './dashboard'

// Road network:
import Regions from './roadnetwork/regions'
import Deu from './roadnetwork/deu'
import Roads from './roadnetwork/roads'
import Reports from './roadnetwork/reports'
import Sections from './roadnetwork/sections'
import SectionGeometry from './roadnetwork/section_geometry'

// Conditions:
import Condition from './condition/condition'
import Pictures from './condition/pictures'
import Traffic from './condition/traffic'

// Treatments
import TreatmentTypes from './treatments/treatment_types'
import TreatmentRates from './treatments/treatment_rates'
import TreatmentMatrix from './treatments/treatment_matrix'

// WorkPlan
import HomoSections from './workplan/homo_sections'
import Plans from './workplan/plans'
import TitleList from './workplan/titlelist'
import Planning from './workplan/old_planning'

// Budget:
import Budgets from './budget/budget'
import WorkProgrammes from './budget/workprogramme'

// Maps
import ParamsComponent from './maps/params_component'
import MapComponent from './maps/map_component'
import ImagesComponent from './maps/images_component'

// Reference data:
//import {Units, Treatments} from './refdata'
import RefData from './refdata/refdata'
import Units from './refdata/units'
import Dropdowns from './refdata/dropdowns'
import WorkCategories from './refdata/workcategories'
import InventoryTypes from  './refdata/inventorytypes'
import InventoryEvents from  './refdata/inventory_events'
import ImportTools from  './refdata/import'
import TrafficSites from './refdata/traffic_sites'

// Settings:
import Settings from './settings/settings'
import History from './settings/history'
import ConditionIndexCriteria from './settings/condition_index_criteria'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: process.env.VUE_APP_VERSION
  },
  actions: { },
  modules: {
    // Old one's
    i18nStore,
    Login,
    Groups,
    Users,
    Bridges,
    Dashboard,

    // Road network:
    Regions,
    Deu,
    Roads,
    Reports,
    Sections,
    SectionGeometry,

    // Condition (survey)
    Condition,
    Pictures,
    Traffic,
    ConditionIndexCriteria,

    // Treatments
    TreatmentTypes,
    TreatmentRates,
    TreatmentMatrix,

    // Workplan:
    Budgets,
    WorkProgrammes,
    HomoSections,
    Planning,
    Plans,
    TitleList,

    // Maps
    ParamsComponent,
    MapComponent,
    ImagesComponent,

    // Reference data:
    Dropdowns,
    RefData,
    Units,
    WorkCategories,
    InventoryTypes,
    InventoryEvents,
    ImportTools,
    TrafficSites,
    
    // Settings:
    Settings,
    History
  }
})
