/* 
File: regions.js
Description: called from Regions.vue, gets region data from DB and passes as a list back to the caller.
*/

import Api from '@/api/roadnetwork'

export default {
  state: {
    list: [],
  },

  actions: {
    async LOAD_RDB_REGIONS_LIST({ commit }) {
      const lang = global.localStorage["mps-locale"]

      try {
        const res = await Api.load_rdb_regions_list(lang)
        const { status } = res
        if (status === 200) {
          commit('SET_RDB_REGIONS_LIST', res.data)
          return 'success'
        } else {
          throw `Load regions list status: ${status}`
        }
      } catch (err) {
        throw `Load regions list: ${err}`
      }
    },

    async LOAD_RDB_REGION_BY_ID({ commit }, rid) {
      try {
        const res = await Api.load_rdb_region_by_id(rid)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_RDB_REGION_BY_ID'
        }
      } catch (err) {
        throw `${err.response.data.message}`
      }
    },

    async ADD_RDB_REGION({commit}, region) {
      try {
        const res = await Api.add_rdb_region(region)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async UPD_RDB_REGION({commit}, payload) {
      try {
        const {id, theRegion} = payload
        const res = await Api.upd_rdb_region(id, theRegion)
        if (res.status === 200) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async DEL_RDB_REGION({ commit }, region_id) {
      try {
        const res = await Api.del_rdb_region(region_id)
        if (res.status === 200) {
          commit('DEL_REGION_FROM_LIST', region_id)
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    }
  },

  mutations: {
    SET_RDB_REGIONS_LIST(state, list) {
      state.list = [...list]
    },
    DEL_REGION_FROM_LIST(state, id) {
      let ind = state.list.findIndex((row) => row.region_id === id)
      if (ind >= 0) state.list.splice(ind, 1)
    },
  },
}