/* 
File: traffic.js
Description: provides routines for traffic formsc calls API's.
*/
import Api from '@/api/traffic';

export default {
  state: {
    list: [],
  },

  actions: {
    async LOAD_TRAFFIC_INSTALLATION({ commit }) {
      try {
        const res = await Api.load_traffic_installation_all();
        const { status } = res;
        if (status === 200) {
          commit('SET_TRAFFIC_INSTALLATION', res.data);
        } else {
          throw `LOAD_TRAFFIC_INSTALLATION status: ${status}`;
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed';
      }
    },
    async LOAD_TRAFFIC_BY_ID({ commit }, id) {
      try {
        const res = await Api.load_traffic_installation_by_id(id);
        const { status } = res;
        if (status === 200) {
          return res.data;
        } else {
          throw 'Error LOAD_TRAFFIC_INSTALLATION_BY_ID';
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed';
      }
    },

    async ADD_TRAFFIC_INSTALLATION({ commit }, traffic) {
      try {
        const res = await Api.add_traffic_installation(traffic);
        if (res.status === 201) {
          return 'success';
        } else {
          throw res.status;
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed';
      }
    },

    async UPD_TRAFFIC_INSTALLATION({ commit }, payload) {
      try {
        const { id, theTraffic } = payload;
        const res = await Api.upd_traffic_installation(id, theTraffic);
        console.log(res)
        if (res.status === 201) {
          return 'success';
        } else {
          throw res.status;
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed';
      }
    },
  },

  mutations: {
    SET_TRAFFIC_INSTALLATION(state, list) {
      state.list = [...list];
    },
  },

  getters: {}
};
