/* 
File: condition.js
Description: provides main routes for condition editing forms, component of the routes.js
*/
const Condition = () => import('@/pages/Condition/Condition.vue')
const ConditionEditForm = () => import('@/pages/Condition/ConditionEditForm.vue')
const ConditionImportForm = () => import('@/pages/Condition/ConditionImportForm.vue')
const GPS = () => import('@/pages/Condition/gps.vue')
const Pictures = () => import('@/pages/Condition/pictures.vue')

const Traffic = () => import('@/pages/Condition/Traffic.vue')
const TrafficEditForm = () => import('@/pages/Condition/TrafficEditForm.vue')
const TrafficImportForm = () => import('@/pages/Condition/TrafficImportForm.vue')

const ConditionDataMenu = [
  /*
     Condition data
  */

  {
    path: 'condition',
    name: 'Condition_Data',
    component: Condition,
  },

  {
    path: 'condition/add',
    name: 'Condition_add',
    component: ConditionEditForm,
    props: { oper: 'add' }

  },
  {
    path: 'condition/upd/:cond_id',
    name: 'Condition_upd',
    component: ConditionEditForm,
    props: { oper: 'upd' }

  },
  
  {
    path: 'gps',
    name: 'GPS Data',
    component: GPS,
  },
  {
    path: 'pictures',
    name: 'Pictures',
    component: Pictures,
  },
  {
    path: 'upload',
    name: 'import',
    component: ConditionImportForm
  },
  /*
     Traffic
  */
  {
    path: 'traffic',
    name: 'Traffic_data',
    component: Traffic,
  },
  {
    path: 'traffic/add',
    name: 'Traffic_add',
    component: TrafficEditForm,
    props: { oper: 'add' }
  },
  {
    path: 'traffic/upd/:tid',
    name: 'Traffic_upd',
    component: TrafficEditForm,
    props: { oper: 'upd' }
  },
  {
    path: 'import',
    name: 'import_traffic',
    component: TrafficImportForm,
  },

]

export default ConditionDataMenu
