/*
File: api/roadnetwork.js
Description: API connections for the async functions for Regions, DEUs and Roads.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  /*---------  REGIONS  ---------------------*/
  load_rdb_regions_list(lang = 'en') {
    return Api.get(`/rdb/region/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  load_rdb_region_by_id(id) {
    return Api.get(`/rdb/region/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_rdb_region(region) {
    return Api.post(`/rdb/region`, region, {
      headers: {
        ...type_json
      }
    })
  },
  upd_rdb_region(id, region) {
    return Api.put(`/rdb/region/${id}`, region, {
      headers: {
        ...type_json
      }
    })
  },
  del_rdb_region(region_id) {
    return Api.delete(`/rdb/region/${region_id}`, {
      headers: {
        ...type_json
      }
    })
  },

  /*---------  DEU  ---------------------*/
  load_rdb_deu_list(lang = 'en', region_id) {
    let apiStr = `/rdb/deu/all?lang=${lang}`
    if (region_id) apiStr += `&region_id=${region_id}`

    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },
  load_rdb_deu_by_id(deu_id) {
    return Api.get(`/rdb/deu/${deu_id}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_rdb_deu(deu) {
    return Api.post(`/rdb/deu`, deu, {
      headers: {
        ...type_json
      }
    })
  },
  upd_rdb_deu(deu_id, deu) {
    return Api.put(`/rdb/deu/${deu_id}`, deu, {
      headers: {
        ...type_json
      }
    })
  },
  del_rdb_deu(deu_id) {
    return Api.delete(`/rdb/deu/${deu_id}`, {
      headers: {
        ...type_json
      }
    })
  },

  /*---------  ROADS  ---------------------*/
  load_rdb_roads_list(lang = 'en', road_class) {
    let apiStr = `/rdb/road/all?lang=${lang}`
    if (road_class) apiStr += `&road_class=${road_class}`
    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },
  load_rdb_road_by_id(road_id) {
    return Api.get(`/rdb/road/${road_id}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_rdb_road(road) {
    return Api.post(`/rdb/road`, road, {
      headers: {
        ...type_json
      }
    })
  },
  upd_rdb_road(road_id, road) {
    return Api.put(`/rdb/road/${road_id}`, road, {
      headers: {
        ...type_json
      }
    })
  },
  del_rdb_road(road_id) {
    return Api.delete(`/rdb/road/${road_id}`, {
      headers: {
        ...type_json
      }
    })
  },
}