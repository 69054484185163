import Api from '@/api/i18n'
import {locales, defaultLocale} from './locales'
import i18n from '../../i18n'

export default {
  state: {
    locales: [],
    active: defaultLocale,
    tr_list: [],
    UiTranslateLoaded: false
  },
  actions: {
    async LOAD_UI_TRANSLATE({commit}, lang) {
      try {
        const res = await Api.load_ui_messages(lang)
        const {status} = res
        if (status === 200 && res.data) {
          i18n.setLocaleMessage(lang, res.data)
          commit('SET_UI_TRANSLATE_LOAD_STATE', true)
        } else {
          throw 'no Data'
        }
      } catch (err) {
        console.log(err)
      }
    },
    async LOAD_TRANSLATION_LIST({commit}) {
      try {
        const res = await Api.load_translate_list()
        const {status} = res
        if (status === 200 && res.data) {
          commit('SET_TRANSLATION_LIST', res.data)
        } else {
          throw 'no Data'
        }
      } catch (err) {
        console.log(err)
      }
    },
    async UPD_TRANSLATE_ENTRY({commit}, payload) {
      const {id, item} = payload
      try {
        const res = await Api.upd_translate_entry(id, item)
        const {status} = res
        if (status === 201) {
          commit('UPDATE_TRANSLATE_ITEM', item)
        } else {
          throw 'no Data'
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : err
      }
    },
    async ADD_TRANSLATE_ENTRY({commit}, item) {
      try {
        const res = await Api.add_translate_entry(item)
        const {status} = res
        if (status === 201 && res.data) {
          commit('ADD_TRANSLATE_ITEM', res.data)
        } else {
          throw 'no Data'
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : err
      }
    }
  },
  mutations: {
    SET_UI_TRANSLATE_LOAD_STATE(state, status) {
      state.UiTranslateLoaded = status
    },
    INIT_LANG: (state) => {
      state.locales = [...locales]
      //state.active = defaultLocale
      i18n.locale = state.active
    },
    CHANGE_LANG(state, code) {
      state.active = code
      localStorage.setItem('mps-locale', code)
      i18n.locale = state.active
    },
    SET_TRANSLATION_LIST(state, list) {
      state.tr_list = list
    },
    UPDATE_TRANSLATE_ITEM(state, item) {
      const {category, key, en, ru, kg} = item
      const ind = state.tr_list.findIndex((item) => {
        return item.category === category && item.key === key
      })
      if (ind > -1) {
        state.tr_list[ind].en = en
        state.tr_list[ind].ru = ru
        state.tr_list[ind].kg = kg
      }
    },
    ADD_TRANSLATE_ITEM(state, item) {
      state.tr_list.push(item)
    }
  },
  getters: {
    locale_active: (state) => state.active,
    isUiTranslateLoaded: (state) => state.UiTranslateLoaded
  }
}
