import {
  emptySectionInfo, emptySectionSurveyInfo, emptyBridgeInfo,
  emptyBridgeSurveyInfo, emptyTunnelInfo, emptyTunnelSurveyInfo
} from "./tree_grid_helper"

export default {
  state: {
    mainInfo: [],
    surveyInfo: [],
    indexInfo: [],
    roadsInfo: [],
    years: [],
    //bridges_coords: [],
    vectorTypes: [
      {
        key: 'roads', value: 'route.roads',
        legend: [
          { colour: '#CC3300', name: 'road_network.class_em' },
          { colour: '#ffcc00', name: 'road_network.class_m' },
          { colour: '#339900', name: 'road_network.class_zh' }
        ]
      },
      {
        key: 'bridges',
        value: 'label.bridges',
        legend: [
          { colour: '#CC3300', name: 'bridges.soundness_imminent' },
          { colour: '#ff9966', name: 'bridges.soundness_critical' },
          { colour: '#ffcc00', name: 'bridges.soundness_poor' },
          { colour: '#99cc33', name: 'bridges.soundness_fair' },
          { colour: '#339900', name: 'bridges.soundness_good' }
        ]
      },
      { key: 'tunnels', value: 'label.tunnels', legend: [] }
    ]
  },

  data() {
    return {
      showLegendBox: true,
      ol: undefined,
    }
  },

  actions: {
    async LOAD_TREE_GRID_INFO({ dispatch, commit }, section_id) {
      // Fill section info
      await dispatch('FILL_TREE_GRID_SECTION_INFO', section_id).then((res) => {
        commit('SET_TREE_GRID_MAIN_INFO', res)
      })
      /* review it 
      // Fill years list
      let years = []
      await dispatch('LOAD_CONDITION_LIST', section_id).then(() => {
        const conditionList = this._modules.root.state.Condition.list
        if (conditionList.length > 0) {
          // Fill the survey years array
          const cat_obj = {}
          conditionList.map((item) => {
            const year = new Date(item.survey_date).getFullYear()
            if (!cat_obj[year]) { cat_obj[year] = true }
          })
          years = Object.keys(cat_obj)
            .map((item) => { return { id: item, name: item } })
            .sort(function (a, b) { return a.id.localeCompare(b.id) })
        }
        commit('SET_SURVEY_YEARS_LIST', years)
      })
      */
      return 'success'
    },

    async FILL_TREE_GRID_SECTION_INFO({ dispatch, commit }, section_id) {
      let result = []
      await dispatch('LOAD_SECTION_BY_ID', section_id).then((res) => {
        const section = Array.isArray(res) ? res[0] : res
        if (section) {
          for (const [attr_name, attr_value] of Object.entries(section)) {
            const ind = emptySectionInfo.findIndex(({ name }) => name === attr_name)
            if (ind >= 0 && attr_value) {
              result.push({ name: emptySectionInfo[ind].name, label: emptySectionInfo[ind].label, value: attr_value })
              if (ind == 3) {
                // put the name of the section
                commit('SET_SELECTION_INFO', attr_value, true)
              }
            }
          }
        }
      })
      return result
    },

    async FILL_TREE_GRID_SECTION_SURVEY_INFO({ dispatch, commit }, payload) {
      const { year, coords } = payload
      //console.log(coords)
      //year = Number(year)
      let result = []
      const fnName = Array.isArray(coords) ? 'GET_CONDITION_DATA_BY_COORDS' : 'LOAD_CONDITION_BY_ID'
      await dispatch(fnName, coords).then(section_data => {
        if (section_data) {
          //const section_data = conditionList.find((el) => { new Date(el.survey_date).getFullYear() == year })
          //console.log("section_data: ", section_data)
          for (const [attr_name, attr_value] of Object.entries(section_data)) {
            const ind = emptySectionSurveyInfo.findIndex(({ name }) => name === attr_name)
            if (ind >= 0 && attr_value) {
              result.push({
                name: emptySectionSurveyInfo[ind].name,
                label: emptySectionSurveyInfo[ind].label,
                value: attr_name === 'survey_date' ? new Date(attr_value).toLocaleDateString() : attr_value
              })
            }
          }
        }
      })
      commit('SET_TREE_GRID_SURVEY_INFO', result)
      return 'success'
    },

    async FILL_TREE_GRID_INDEX_INFO({ commit }, year) {
      /*
        commit('SET_TREE_GRID_INDEX_INFO', result)
                                              } else {
                                                ind = this.treeGridData[2].children.findIndex(({ name }) => name === attr_name)
                                                if (ind >= 0) {
                                                  this.treeGridData[1].children[ind].value = attr_value
                                                } else {
                                                  //console.log(attr_name, " not found ")
                                                }
                                              }
                                            }
            
        */
      commit('SET_TREE_GRID_INDEX_INFO', [])
      return 'success'
    },

    async FILL_TREE_GRID_BRIDGE_INFO({ dispatch, commit }, uuid) {
      let result_info = []
      let result_survey = []
      await dispatch('LOAD_BRIDGE_DETAILS', uuid).then((res) => {
        if (res) {
          for (const [attr_name, attr_value] of Object.entries(res)) {
            const ind1 = emptyBridgeInfo.findIndex(({ name }) => name === attr_name)
            const ind2 = emptyBridgeSurveyInfo.findIndex(({ name }) => name === attr_name)
            if (attr_value) {
              if (ind1 >= 0) result_info.push({
                name: emptyBridgeInfo[ind1].name,
                label: emptyBridgeInfo[ind1].label,
                value: attr_value
              })
              if (ind2 >= 0) result_survey.push({
                name: emptyBridgeSurveyInfo[ind2].name,
                label: emptyBridgeSurveyInfo[ind2].label,
                value: attr_value
              })
            }
          }
        }
      })
      commit('SET_TREE_GRID_MAIN_INFO', result_info)
      commit('SET_TREE_GRID_SURVEY_INFO', result_survey)
      return 'success'
    },

    async FILL_TREE_GRID_TUNNEL_INFO({ dispatch, commit }, uuid) {
      let result_info = []
      let result_survey = []
      await dispatch('LOAD_TUNNEL_DETAILS', uuid).then((res) => {
        if (res) {
          for (const [attr_name, attr_value] of Object.entries(res)) {
            const ind1 = emptyTunnelInfo.findIndex(({ name }) => name === attr_name)
            const ind2 = emptyTunnelSurveyInfo.findIndex(({ name }) => name === attr_name)
            if (attr_value) {
              if (ind1 >= 0) result_info.push({
                name: emptyTunnelInfo[ind1].name,
                label: emptyTunnelInfo[ind1].label,
                value: attr_value
              })
              if (ind2 >= 0) result_survey.push({
                name: emptyTunnelSurveyInfo[ind2].name,
                label: emptyTunnelSurveyInfo[ind2].label,
                value: attr_value
              })
            }
          }
        }
      })
      commit('SET_TREE_GRID_MAIN_INFO', result_info)
      commit('SET_TREE_GRID_SURVEY_INFO', result_survey)
      return 'success'
    },

    async LOAD_ROADS_GRID_INFO({ dispatch, commit }) {
      let result = [
        { id: 1, value: 'ЭМ', param: 'road_class', label: 'ЭМ Roads', children: [] },
        { id: 2, value: 'М', param: 'road_class', label: 'М Roads', children: [] },
        { id: 3, value: 'Ж', param: 'road_class', label: 'Ж Roads', children: [] },
        //{ name: '?', label: '?', children: [] }
      ]
      // Change to API call when it will be ready
      await dispatch('LOAD_SECTIONS_LIST', {for_map: true}).then(() => {
        const res = this._modules.root.state.Sections.list
        res.forEach(section => {
          let index = 3
          switch (section.road_key.at(0)) {
            case 'Э': index = 0; break
            case 'М': index = 1; break
            case 'Ж': index = 2; break
            //default: index = 3
          }

          if (index < 3) {
            let i = result[index].children.findIndex(road => road.id === section.road_id)

            if (i == -1) {
              i = result[index].children.push({ 
                id: section.road_id, 
                value: section.road, 
                param: 'fk_road', 
                label: 'Road', 
                children: [] 
              }) - 1
            }
            result[index].children[i].children.push({
              id: section.section_id,
              value: section.section_description,
              param: 'section_id', 
              label: 'Section',
              children: []
            })
          }
        })
      })

      /* // change to this if needed to show tree as Region/Deu/Road/Section
      await dispatch('GET_REGIONS_DROPDOWN', false).then(() => {
        const res = this._modules.root.state.Dropdowns.regions
        res.forEach(region => {
          result.push({ name: region.region_id, label: 'region', value: region.region_description, children: { value: [] } })
        })
      })
      await dispatch('LOAD_DEU_LIST', false).then(() => {
        const res = this._modules.root.state.Deu.list
        res.forEach(deu => {
          //Object.defineProperty(deu, 'children', [])
          const index = res.findIndex(({ region }) => region.region_id === res.fk_region)
          result[index].children.push({ name: deu.deu_id, label: 'deu', value: deu.deu_description, children: { value: [] } })
        })
      })
      await dispatch('LOAD_SECTIONS_LIST', {}).then(() => {
        const res = this._modules.root.state.Sections.list
      
        res.forEach(section => {
          const index = res.findIndex(({ region }) => region.region_id === res.fk_region)
          result[index].children.push({ name: section.section_id, label: 'section', value: section.section_description, children: { value: [] } })
        })
      })
      console.log('result', result)
      */
      commit('SET_ROADS_GRID_INFO', result)
    },
    /*
        async LOAD_SHOW_TYPES({ dispatch }) {
          await dispatch('LOAD_CONDITION_LIST', this.section_id).then(() => {
            const conditionList = this.$store.state.Condition.list
            if (this.emptyData) return // no data for the given section
    
          })
        },
      */
  },

  mutations: {
    SET_TREE_GRID_MAIN_INFO(state, list) {
      state.mainInfo = [...list]
    },
    SET_TREE_GRID_SURVEY_INFO(state, list) {
      state.surveyInfo = [...list]
    },
    SET_TREE_GRID_INDEX_INFO(state, list) {
      state.indexInfo = [...list]
    },
    SET_SURVEY_YEARS_LIST(state, list) {
      state.years = [...list]
    },
    SET_ROADS_GRID_INFO(state, list) {
      state.roadsInfo = [...list]
    },
    SET_TUNNELS_LEGEND(state, list) {
      //const ind = state.vectorTypes.findIndex((vec) => vec.key === 'tunnels')
      //state.vectorTypes[ind].legend = [...list]
      state.vectorTypes[2].legend = [...list]
    },

  },

}