/* 
File: roads.js
Description: called from Roads.vue and RoadsList.vue, gets road data from DB and passes as a list back to the caller.
*/
import Api from '@/api/roadnetwork'

export default {
  state: {
    list: [],
  },
  actions: {
    async LOAD_ROADS_LIST({ commit }, road_class) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_rdb_roads_list(lang, road_class)
        const { status } = res
        if (status === 200) {
          commit('SET_ROADS_LIST', res.data)
          return 'success';
        } else {
          throw `Error LOAD_ROADS_LIST: ${status}`
        }
      } catch (err) {
        throw `LOAD_ROADS_LIST: ${err}`
      }
    },

    async LOAD_ROAD_BY_ID({ commit }, rid) {
      try {
        const res = await Api.load_rdb_road_by_id(rid)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_ROAD_BY_ID'
        }
      } catch (err) {
        throw `${err.response.data.message}`
      }
    },

    async ADD_ROAD({ commit }, newRoad) {
      try {
        const res = await Api.add_rdb_road(newRoad)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async UPD_ROAD({ commit }, payload) {
      try {
        const { id, theRoad } = payload
        const res = await Api.upd_rdb_road(id, theRoad)
        if (res.status === 200) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async DEL_ROAD({ commit }, road_id) {
      try {
        const res = await Api.del_rdb_road(road_id)   //{status: 200}
        if (res.status === 200) {
          commit('DEL_ROAD_FROM_LIST', road_id)
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    }
  },

  mutations: {
    SET_ROADS_LIST(state, list) {
      state.list = [...list]
    },
    DEL_ROAD_FROM_LIST(state, id) {
      let ind = state.list.findIndex((row) => row.road_id === id)
      if (ind >= 0) {
        state.list.splice(ind, 1)
      }
    },
  },

  getters: {
    history_for() {
      //return this.$store.state.Roads.use_history
      return localStorage.getItem('history_for')
    },
    history() {
      //return this.$store.state.Roads.history
      return JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('history')))))
    },
    roadsByClass: (state) => (road_class) => {
      return state.list.filter(el => !road_class || el.road_class === road_class)  // Проверка если передан параметр
    },
  }
}