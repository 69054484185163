/* 
File: workprogramme.js
Description: called from MaintenancePlan_all.vue and MaintenancePlan_single.vue.
*/
import Api from '@/api/budget';
//import budget from "./budget"

let populatedList = [{ budget_id: null, year: null, list: null }];

export default {
  state: {
    list: [],
    all_list: [],
    isWPOper: false,
  },

  actions: {
    async LOAD_ALL_WP_LIST({ commit }) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_works_all(lang)
        const { status } = res
        if (status === 200) {
          commit('SET_ALL_WP_LIST', res.data)
        } else {
          throw `Load workprogrammes list status: ${status}`
        }
      } catch (err) {
        throw `Load workprogrammes list: ${err}`
      } finally {
      }
    },

    async LOAD_WP_BY_YEAR({ commit }, year) {
      const byYear = workProgrammes.find((element) => element.Year == year)
      return byYear
    },

    async ADD_WP({ commit }, theBudget) {
      workProgrammes.push(theBudget)
      return 'success'
    },

    async UPD_WP({ commit }, payload) {
      let i = 0
      workProgrammes.forEach((element) => {
        if (element.budget_id == payload.budget_id) budgetsTable.splice(i, 1, payload)
        i++
      });
      return 'success'
    },

    async CLEAR_LIST({ commit }, payload) {
      commit('SET_WP_LIST', [])
    },

    async LOAD_POPULATED_WP_LIST({ commit }, { fk_region, year }) {
      try {
        const res = await Api.load_works(fk_region, year)
        const { status } = res
        if (status === 200) {
          const result = res.data.filter((item) => item.fk_budget == item.budget_id)
          if (result.length == 0) return

          commit('SET_WP_LIST', result)
        } else {
          throw `LOAD_POPULATED_WP_LIST status: ${status}`
        }
      } catch (err) {
        throw `Error LOAD_POPULATED_WP_LIST: ${err}`
      }
    },

    async RELOAD_LIST({ commit }) {
      commit('SET_WP_LIST', this.state.list)
    },

    async LOAD_UNASSIGNED({ context }, { fk_region, year, limit }) {
      try {
        const res = await Api.load_works(fk_region, year)
        const { status } = res
        if (status === 200) {
          // filter by unassigned (fk_budget == -1)
          const result = res.data.filter((item) => (item.fk_budget == -1) && item.financial_mln_kgs <= limit)
          //          console.log(result)
          return result //res.data.slice(0, lastIndex + 1)
        } else {
          throw `LOAD_UNASSIGNED status: ${status}`
        }
      } catch (err) {
        throw `Error LOAD_UNASSIGNED: ${err}`
      }
    },

    async POPULATE_WP_LIST({ commit, dispatch }, { fk_region, year, budget_id }) {
      try {
        const { status, data } = await Api.load_works(fk_region, year);

        if (status !== 200) {
          throw new Error(`POPULATE_WP_LIST status: ${status}`);
        }

        // Фильтрация по не назначенным (fk_budget == -1)
        const lastIndex = 1 + data.findLastIndex(item =>
          item.running_total_financial_mln_kgs <= item.budgeted_amount_mln_kgs
        );

        const returnList = data.slice(0, lastIndex);
        let remainingData = data.slice(lastIndex);

        if (remainingData.length > 0) {
          const budgetedAmount = returnList[0].budgeted_amount_mln_kgs;
          const currentTotal = returnList[lastIndex - 1].running_total_financial_mln_kgs;
          let limit = budgetedAmount - currentTotal;

          // Фильтрация и добавление дополнительных элементов в returnList
          remainingData = remainingData.filter(item => item.fk_budget === -1 && item.financial_mln_kgs <= limit);

          remainingData.forEach(item => {
            if (item.financial_mln_kgs <= limit) {
              returnList.push(item);
              limit -= item.financial_mln_kgs;
            }
          });
        }

        // Сохранение и возвращение количества
        const keys = returnList.map(work => work.section_maintenance_plan_id);
        await dispatch('ASSIGN_WP_BUDGET', { budget_id, section_maintenance_plan_id: keys });

        commit('SET_WP_LIST', returnList);
        return returnList.length;

      } catch (err) {
        throw new Error(`Error POPULATE_WP_LIST: ${err}`);
      }
    },
    async ASSIGN_WP_BUDGET({ commit }, items) {
      try {
        const res = await Api.assign_budget(items)
        if (res.status === 200) {
          return 'success'
        } else {
          throw res.status
        }
        commit('SET_WP_LIST', list)
      } catch (err) {
        throw `Error ASSIGN_WP_BUDGET: ${err}`
      } finally {
      }
    }

  },

  methods: {
  },

  mutations: {
    SET_WP_LIST(state, list) {
      state.list = [...list];
    },
    SET_ALL_WP_LIST(state, list) {
      state.all_list = [...list];
    },
    SET_WP_OPER(state, val) {
      state.isWPOper = val;
    },
  },
  getters: {}
}
