/*
File: api/i18n.js
Description: API connections for the async functions for translations Mgmt.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  /*  ---------  TRANSLATING  ---------------------*/
  /** list of all translating keys
   * @return {Promise<*> [item]}
   * item: {
        "id": number,
        "category": string,
        "key": string,
        "en": string,
        "ru": string,
        "kg": string
        }
   ,} - 200  Default Response
   * @throws Error
   */
  load_translate_list() {
    return Api.get(`/localisation/all`, {
      headers: {
        ...type_json
      }
    })
  },
  /** User interface's translate
   * @return {Promise<*> {item}}
   * category: {
   *  key: string
   * } - 200  Default Response
   * @throws Error
   */
  load_ui_messages(lang) {
    return Api.get(`/localisation/${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  /** add translate entry
   * @param {object} entry
   * {
      "category": "string",
      "key": "string",
      "en": "string",
      "kg": "string",
      "ru": "string"
    }
   * @return {Promise<*> {}} - 201  Default Response
   * {
      "id": integer,
      "category": "string",
      "key": "string",
      "en": "string",
      "kg": "string",
      "ru": "string"
    }
   * @throws Error
   */
  add_translate_entry(item) {
    return Api.post(
      `/localisation`,
      { ...item },
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  /** upd translate entry
   * @param {integer} id
   * @param {object} entry
   * {
      "category": "string",
      "key": "string",
      "en": "string",
      "kg": "string",
      "ru": "string"
    }
   * @return {Promise<*> []} - 201  Default Response
   * @throws Error
   */
  upd_translate_entry(id, item) {
    return Api.put(
      `/localisation/${id}`,
      { ...item },
      {
        headers: {
          ...type_json
        }
      }
    )
  },
}