/*
File: api/budget.js
Description: API connections for the async functions for Budget and Works.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  /*--------- Budget  ---------------------*/
  load_budget(lang = 'en') {
    return Api.get(`/rdb/budget/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  load_budget_by_id(id, lang = 'en') {
    return Api.get(`/rdb/budget/${id}?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_budget(budget) {
    return Api.post(`/rdb/budget`, budget, {
      headers: {
        ...type_json
      }
    })
  },
  upd_budget(id, budget) {
    return Api.put(`/rdb/budget/${id}`, budget, {
      headers: {
        ...type_json
      }
    })
  },
  del_budget(budget_id) {
    return Api.delete(`/rdb/budget/${budget_id}`, {
      headers: {
        ...type_json
      }
    })
  },

  assign_budget(item) { //item: {budget_id, [id1, id2 ...]}
    //    const { budget_id, section_maintenance_plan_id } = item
    //    console.log(budget_id, section_maintenance_plan_id)
    item.budget_id = Number(item.budget_id)
    return Api.put("/rdb/section_maintenance_plan/assign_budget", item,
      //{ ...item },
      {
        headers: {
          ...type_json
        }
      }
    )
  },

  /*--------- Works  ---------------------*/
  load_works_all(lang = 'en') {
    return Api.get(`/rdb/section_maintenance_plan/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },

  load_works(region_id, year) {
    return Api.get(`/rdb/section_maintenance_plan/report_budget_assigned?year=${year}&region_id=${region_id}`, {
      headers: {
        ...type_json
      }
    })
  },
}