/*
File: api/coords.js
Description: API connections for the async functions for drawing maps.
*/
import Api_ref from '@/api'

//import descr_json from '../store/jsons/descr.json'
//import all_coords from '../store/jsons/all_coords.json'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  // for drawing map
  load_map_roads_coords(region_id, road_id, deu_id, section_id) {
    //all_unpaved.push(all_paved)
  //  const res = { status: 200, data: all_coords }
    return { status: 200, data: []}   // res
    /* 
      // Uncomment after Andrey copies all the data to the DB
       const lang = global.localStorage["mps-locale"]
        //    let apiStr = `/rdb/section/coords_zip?lang=${lang}`
        let apiStr = `/rdb/section/coords?lang=${lang}`
        if (region_id) apiStr += `&region_id=${region_id}`
        if (road_id) apiStr += `&road_id=${road_id}`
        if (deu_id) apiStr += `&deu_id=${deu_id}`
        if (section_id) apiStr += `&section_id=${section_id}`
    
        return Api.get(apiStr, {
          headers: {
            ...type_json
          }
        })
        */
  },

  //load_windowed_map_coords(region_id, road_id, deu_id, section_id, zoom, start_x, start_y, end_x, end_y, data_100_column_name) {
  load_windowed_map_coords(payload) {
    const { region_id, road_id, deu_id, section_id, zoom, start_x, start_y, end_x, end_y, data_100_column_name } = payload

    let apiStr = `/rdb/section/coords_x_y?`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (road_id) apiStr += `&road_id=${road_id}`
    if (deu_id) apiStr += `&deu_id=${deu_id}`
    if (section_id) apiStr += `&section_id=${section_id}`
    if (zoom) apiStr += `&zoom=${zoom}`
    if (start_x) apiStr += `&start_x=${start_x}`
    if (start_y) apiStr += `&start_y=${start_y}`
    if (end_x) apiStr += `&end_x=${end_x}`
    if (end_y) apiStr += `&end_y=${end_y}`
    if (data_100_column_name) apiStr += `&data_100_column_name=${data_100_column_name}`
    
    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },

  load_descr(lang = 'en') {
    const res = { status: 200, data: descr_json.descr }
    return res
  },

}