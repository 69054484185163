/* 
File: condition.js
Description: API connections for the async functions for Condition.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  load_condition_all(lang = 'en', section_id, hs_id, column_name, survey_year) {
    let apiStr = `/rdb/data100/all?lang=${lang}&section_id=${section_id}`
    if (hs_id) apiStr += `&hs_id=${hs_id}`
    if (column_name) apiStr += `&column_name=${column_name}`
    if (survey_year) apiStr += `&survey_year=${survey_year}`
    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },
  load_condition_by_id(lang = 'en', data_100_id) {
    return Api.get(`/rdb/data100/${data_100_id}?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  // get Condition data from data100 by provided x and y (clicked on the map)
  get_condition_data_by_coords(x, y) {
    return Api.get(`/rdb/data100/get_by_x_y?x=${x}&y=${y}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_condition(condition) {
    return Api.post(`/rdb/data100`, condition, {
      headers: {
        ...type_json
      }
    })
  },
  upd_condition(id, condition) {
    return Api.put(`/rdb/data100/${id}`, condition, {
      headers: {
        ...type_json
      }
    })
  },
  del_condition(data_100_id) {
    return Api.delete(`/rdb/data100/${data_100_id}`, {
      headers: {
        ...type_json
      }
    })
  },
}