/* 
File: treatment_matrix.js
Description: .
*/
import Api from '@/api/treatments'

export default {
  state: {
    list: [],
  },
  actions: {
    async LOAD_TREATMENT_MATRIX({ commit }) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_treatment_matrix(lang)
        const { status } = res
        if (status === 200) {
          commit('SET_TREATMENT_MATRIX', res.data)
        } else {
          throw `Load treatment matrix status: ${status}`
        }
      } catch (err) {
        throw `Load treatment matrix: ${err}`
      }
    },

    async LOAD_TREATMENT_MATRIX_ITEM({ commit }, id) {
      try {
        const res = await Api.load_treatment_matrix_item(id)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_ROAD_BY_ID'
        }
      } catch (err) {
        throw `${err.response.data.message}`
      }
    },

    async ADD_TREATMENT_MATRIX_ITEM({ commit }, item) {
      try {
        const res = await Api.add_treatment_matrix_item(item)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async UPD_TREATMENT_MATRIX({ commit }, payload) {
      try {
        const { id, item } = payload
        const res = await Api.upd_treatment_matrix_item(id, item)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async DEL_TREATMENT_MATRIX({ commit }, id) {
      try {
        const res = await Api.del_treatment_matrix_item(id)   //{status: 200}
        if (res.status === 200) {
          commit('DEL_TREATMENT_MATRIX_FROM_LIST', id)
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    }
  },

  mutations: {
    SET_TREATMENT_MATRIX(state, list) {
      state.list = [...list]
    },
    DEL_TREATMENT_MATRIX_FROM_LIST(state, id) {
      let ind = state.list.findIndex((row) => row.treatment_matrix_id === id)
      if (ind >= 0) {
        state.list.splice(ind, 1)
      }
    },
  },
  getters: {}
}
