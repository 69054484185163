/* 
File: condition_index_criteria.js
Description: API connections for the async functions for Condition Index.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  load_condition_index_criteria_all(lang = 'en') {
    let apiStr = `/condition_index_criteria/all?lang=${lang}`
    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },
  load_condition_index_criteria_pivot(lang = 'en') {
    let apiStr = `/condition_index_criteria/pivot?lang=${lang}`
    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },
  load_condition_index_criteria_by_id(lang = 'en', id) {
    return Api.get(`/condition_index_criteria/${id}?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_condition_index_criteria(condition) {
    return Api.post(`/condition_index_criteria`, condition, {
      headers: {
        ...type_json
      }
    })
  },
  upd_condition_index_criteria(id, condition) {
    return Api.put(`/condition_index_criteria/${id}`, condition, {
      headers: {
        ...type_json
      }
    })
  },
  del_condition_index_criteria(id) {
    return Api.delete(`/condition_index_criteria/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
}