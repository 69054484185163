/* 
File: planning.js
Description: API connections for the async functions for old planning functionality.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  /*---------  PLANS  ---------------------*/
  load_plan_list(lang = 'en', region_id, year) {
    let apiStr = `/plan/all?lang=${lang}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (year) apiStr += `&year=${year}`
    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },
  load_plan_by_id(lang = 'en', id) {
    return Api.get(`/plan/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_plan(payload) {
    return Api.post(`/plan`, payload, {
      headers: {
        ...type_json
      }
    })
  },
  approve_plan(id){   //payload) {
    return Api.put(`/plan/approve/${id}`, {   //payload, {
      headers: {
        ...type_json
      }
    })
  },
  cancel_plan(id){ 
    return Api.put(`/plan/cancel_approval/${id}`, { 
      headers: {
        ...type_json
      }
    })
  },
  upd_plan(id, plan) {
    return Api.put(`/plan/${id}`, plan, {
      headers: {
        ...type_json
      }
    })
  },
  delete_plan(id){ 
    return Api.delete(`/plan/cancel_approval/${id}`, { 
      headers: {
        ...type_json
      }
    })
  },
  /** get current plan
   * @return {Promise<*> []} - 200  Default Response
   * {
       "plan_id": Integer
   * }
   * @throws Error
   */
  load_plan_current() {
    return Api.get(`/planning/plans/current`, {
      headers: {
        ...type_json
      }
    })
  },

  /** load plan by id
   * @param {integer} id // user id
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  /** changing plan lock status
   * @param {integer} id
   * {
      "plan_id": "integer",
    }
   * @return {Promise<*> {}} - 200  Default Response
   {
    "id": 10,
    "name": "newPlan2020-5years",
    "created_at": "2020-04-27 21:47:10",
    "updated_at": "2020-09-14 10:37:11",
    "locked_at": null
    }
   * @throws Error
   */
  plan_change_lock_status(id) {
    return Api.put(`/planning/plans/${id}/lock`, {
      headers: {
        ...type_json
      }
    })
  },

  /** upd plan
   * @param {integer} id
   * @param {object} plan
   * {
      "name": "string",
    }
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */


  /*  ---------  PLANNING  ---------------------*/
  /** */
  /** get av works list
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_av_works_list(id = null) {
    return Api.get(
      `/planning/plans/${id}/available_works`,
      { plan_id: id },
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  /** get av work description
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_av_work_info(id) {
    return Api.get(`/planning/works/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  /** get plan's list
   * @return {Promise<*> {
   *  "id": integer,
   *  "name": string}}
   * - 200  Default Response
   * @throws Error
   */
  load_plans_list() {
    return Api.get(`/planning/plans`, {
      headers: {
        ...type_json
      }
    })
  },
  /** get plan info
   * @return {Promise<*> {
   *  "id": integer,
   *  "name": string}
   *  "created_at": date}
   * - 200  Default Response
   * @throws Error
   */
  load_plan_info(id) {
    return Api.get(`/planning/plans/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  /** get plan works
   * @return {Promise<*> {}} - 200  Default Response
   * @throws Error
   */
  load_plan_works(id) {
    return Api.get(`/planning/plans/${id}/works`, {
      headers: {
        ...type_json
      }
    })
  },
  /** add new works to plan
   * @param {integer} id // Plan id
   * @param {Array[string]} works // ["id1", "id2", ...]
   * @return {Promise<*> {}} - 200  Default Response
   * @throws Error
   */
  add_plan_works(id, works) {
    return Api.post(
      `/planning/plans/${id}/works`,
      { work_ids: works },
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  /** plan work info
   * @param {integer} plan_id // Plan id
   * @param {integer} work_id // Plan work id
   * @return {Promise<*> {}} - 200  Default Response
   * @throws Error
   */
  load_plan_work_info(plan_id, work_id) {
    return Api.get(`/planning/plans/${plan_id}/works/${work_id}`, {
      headers: {
        ...type_json
      }
    })
  },
  /**  plan works approval
   * @param {integer} id // Plan id
   * @param {Array[string]} works // ["id1", "id2", ...]
   * @return {Promise<*>
   *  Array[{
   *     "id": 24,
   *     "field": "approved_ddh_by",
   *     "value": null
   *  }]
   * } - 200  Default Response
   * * @throws Error
   */
  plan_works_approval(id, works) {
    return Api.put(
      `/planning/plans/${id}/works/approval`,
      { work_ids: works },
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  /** export plan
   * @param {integer} plan_id // Plan id
   * @return {Promise<*> {blob}} - 200  Default Response
   * @throws Error
   */
  plan_export(plan_id, body) {
    return Api.post(`/planning/plans/${plan_id}/export`, { ...body }, {
      responseType: 'blob',
      timeout: 30000,
      headers: {
        Accept: 'application/vnd.ms-excel',
        ...type_json
      }
    })
  },


  /*  ---------  DASHBOARD  ---------------------*/
  /** Charts data
   * @return {Promise<*> {}} - 200 Response
   * @throws Error
   */
  load_dashboard_charts_data(plan_id) {
    return Api.get(`/planning/plans/${plan_id}/stats`, {
      headers: {
        ...type_json
      }
    })
  },
}