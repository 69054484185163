/* 
File: condition.js
Description: called from Condition.vue, provides routines for conditon edit forms calling API's.
*/
import Api from '@/api/condition'

export default {
  state: {
    list: []
  },
  actions: {
    async LOAD_CONDITION_LIST({ commit }, section_id, hs_id) {
      const lang = global.localStorage["mps-locale"]
      if (section_id == null || section_id == '') {
        commit('SET_CONDITION_LIST', [])
        return 'success'
      }
      try {
        const res = await Api.load_condition_all(lang, section_id, hs_id)
        const { status } = res
        if (status === 200) {
          commit('SET_CONDITION_LIST', res.data)
          return 'success';
        } else {
          throw `Error LOAD_CONDITION_LIST: ${status}`
        }
      } catch (err) {
        throw `LOAD_CONDITION_LIST: ${err}`
      }
    },

    async LOAD_CONDITION_BY_ID({ commit }, cond_id) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_condition_by_id(lang, cond_id)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_CONDITION_BY_ID'
        }
      } catch (err) {
        throw `${err.response.data.message}`
      }
    },

    async ADD_CONDITION({ commit }, condition) {
      try {
        const res = await Api.add_condition(condition)
        if (res.status === 201) {
          return res.data.cond_id
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async UPD_CONDITION({ commit }, payload) {
      try {
        const { id, condition } = payload
        const res = await Api.upd_condition(id, condition)
        if (res.status === 200) {
          return res.data.cond_id
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async DEL_CONDITION({ commit }, cond_id) {
      try {
        const res = await Api.del_condition(cond_id)
        if (res.status === 200) {
          commit('DEL_CONDITION_FROM_LIST', cond_id)
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async LOAD_CONDITION_DATA_FOR_CHART({ }, payload) {
      const lang = global.localStorage["mps-locale"]
      const { section_id, hs_id, column_name, survey_year } = payload
      if (section_id == null || section_id == '') {
        return []
      }
      try {
        const res = await Api.load_condition_all(lang, section_id, hs_id, column_name, survey_year)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw `Error LOAD_CONDITION_DATA_FOR_CHART: ${status}`
        }
      } catch (err) {
        throw `LOAD_CONDITION_DATA_FOR_CHART: ${err}`
      }
    },

    async GET_CONDITION_DATA_BY_COORDS({ }, coords) {
      //const [ x, y ] = coords
      const x = Math.round(coords[0])
      const y = Math.round(coords[1])
      try {
        const res = await Api.get_condition_data_by_coords(x, y)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          return {} //throw `Error GET_CONDITION_DATA_BY_COORDS: ${status}`
        }
      } catch (err) {
        return {} //throw `GET_CONDITION_DATA_BY_COORDS: ${err}`
      }
    },
  },

  mutations: {
    SET_CONDITION_LIST(state, list) {
      state.list = [...list]
    },
    DEL_CONDITION_FROM_LIST(state, id) {
      let ind = state.list.findIndex((row) => row.data_id === id)
      if (ind >= 0) state.list.splice(ind, 1)
    },
  }
}
