/*
File: api/inventory.js
Description: API connections for the async functions for Inventory Types and Events.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
/*---------  Inventory Types  ---------------------*/
load_inventory_types_list(lang = 'en') {
    return Api.get(`/rdb/inventory_type/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  load_rdb_inventory_type_by_id(inventory_type_id) {
    return Api.get(`/rdb/inventory_type/${inventory_type_id}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_rdb_inventory_type(newInventoryType) {
    return Api.post(`/rdb/inventory_type`, newInventoryType, {
      headers: {
        ...type_json
      }
    })
  },
  upd_rdb_inventory_type(inventory_type_id, theInventoryType) {
    return Api.put(`/rdb/inventory_type/${inventory_type_id}`, theInventoryType, {
      headers: {
        ...type_json
      }
    })
  },

  /*---------  Inventory Events  ---------------------*/
  load_inventory_events_list(lang = 'en') {
    return Api.get(`/rdb/inventory_type_event/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  load_rdb_inventory_event_by_id(inventory_type_event_id) {
    return Api.get(`/rdb/inventory_type_event/${inventory_type_event_id}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_rdb_inventory_event(newInventoryEvent) {
    return Api.post(`/rdb/inventory_type_event`, newInventoryEvent, {
      headers: {
        ...type_json
      }
    })
  },
  upd_rdb_inventory_event(inventory_type_event_id, theInventoryEvent) {
    return Api.put(`/rdb/inventory_type_event/${inventory_type_event_id}`, theInventoryEvent, {
      headers: {
        ...type_json
      }
    })
  },
}