/* 
File: workcategories.js
Description: called from WorkCategories.vue and WorkCategoryEditForm.vue, 
gets Work Categories data from DB and passes as a list back to the caller.
*/
import Api from '@/api/reference_data'

export default {
  state: {
    list: []
  },

  actions: {
    async LOAD_WORK_CATEGORIES_LIST({ commit }) {
      const lang = global.localStorage["mps-locale"]

      try {
        const res = await Api.load_work_categories(lang)
        const { status } = res
        if (status === 200) {
          commit('SET_WORK_CATEGORIES_LIST', res.data)
          return 'success';
        } else {
          throw `Error LOAD_WORK_CATEGORIES_LIST: ${status}`
        }
      } catch (err) {
        throw `LOAD_WORK_CATEGORIES_LIST: ${err}`
      }
    },

    async LOAD_WORK_CATEGORY_BY_ID({ commit }, work_category_id) {
      try {
        const res = await Api.load_work_category_by_id(work_category_id)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_WORK_CATEGORY_BY_ID'
        }
      } catch (err) {
        throw `${err.response.data.message}`
      }
    },

    async ADD_WORK_CATEGORY({ commit }, work_category) {
      try {
        const res = await Api.add_work_category(work_category)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async UPD_WORK_CATEGORY({ commit }, payload) {
      try {
        const { id, work_category } = payload
        const res = await Api.upd_work_category(id, work_category)
        if (res.status === 201) {       //200) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },
  },

  mutations: {
    SET_WORK_CATEGORIES_LIST(state, list) {
      state.list = [...list]
    }
  },

  getters: {}
}