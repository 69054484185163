/* 
File: traffic_sites.js
Description: provides routines for traffic sites forms, calls API's.
*/
import Api from '@/api/traffic';

export default {
  state: {
    list: []
  },
  actions: {
    async LOAD_TRAFFIC_SITES({ commit }) {
      const lang = global.localStorage["mps-locale"]
      try {
        const res = await Api.load_traffic_site_all(lang);
        const { status } = res;
        if (status === 200) {
          commit('SET_TRAFFIC_SITES', res.data);
        } else {
          throw `LOAD_TRAFFIC_SITES status: ${status}`;
        }
      } catch (err) {
        throw `LOAD_TRAFFIC_SITES: ${err}`;
      } finally {
      }
    },

    async LOAD_TRAFFIC_SITE_BY_ID({ commit }, id) {
      try {
        const res = await Api.load_traffic_site_by_id(id);
        const { status } = res;
        if (status === 200) {
          return res.data;
        } else {
          throw 'Error LOAD_TRAFFIC_SITE_BY_ID';
        }
      } catch (err) {
        throw `${err.response.data.message}`;
      } finally {
      }
    },

    async ADD_TRAFFIC_SITE({ commit }, traffic_site) {
      try {
        const res = await Api.add_traffic_site(traffic_site);
        if (res.status === 201) {
          return 'success';
        } else {
          throw res.status;
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed';
      } finally {
      }
    },
    
    async UPD_TRAFFIC_SITE({ commit }, payload) {
      try {
        const { id, traffic_site } = payload;
        const res = await Api.upd_traffic_site(id, traffic_site);
        if (res.status === 201) {
          return 'success';
        } else {
          throw res.status;
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed';
      } finally {
      }
    },

    async DEL_TRAFFIC_SITE({ commit }, traffic_site_id) {
      try {
        const res = await Api.del_traffic_site(traffic_site_id)
        if (res.status === 200) {
          commit('DEL_TRAFFIC_SITE_FROM_LIST', traffic_site_id)
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    }
  },

  mutations: {
    SET_TRAFFIC_SITES(state, list) {
      state.list = [...list];
    },
    DEL_TRAFFIC_SITE_FROM_LIST(state, id) {
      let ind = state.list.findIndex((row) => row.traffic_site_id === id)
      if (ind >= 0) state.list.splice(ind, 1)
    },
  },

  getters: {
    roadsInTrafficSitesList: (state) => {
      const uniqueIds = new Set();
      const res = state.list.reduce((acc, item) => {
        if (!uniqueIds.has(item.fk_road)) {
          uniqueIds.add(item.fk_road);
          acc.push({ id: item.fk_road, name: item.road });
        }
        return acc;
      }, [])
      .sort((a, b) => !a.name ? 0 : a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))

      res.unshift({ id: 0, name: '' })   // Add null value to the beginnig
      return res
    },

  }
};
