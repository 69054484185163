/*
File: api/import.js
Description: API connections for the async functions for bulk importing.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  /*--------- Roads bulk importing  ---------------------*/
  get_rdb_road_import_template() {
    return Api.get('/rdb/road/import_template', {
      responseType: "blob",
      headers: {
        ...type_json
      }
    })
  },

  import_rdb_road(import_file) {
    return Api.post('/rdb/road/import', import_file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  
  /*--------- Sections bulk importing  ---------------------*/
  get_rdb_section_import_template() {
    return Api.get('/rdb/section/import_template', {
      responseType: "blob",
      headers: {
        ...type_json
      }
    })
  },
  import_rdb_section(import_file) {
    return Api.post('/rdb/section/import', import_file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  /*--------- IRI/Rutting bulk importing  ---------------------*/
  get_rdb_iri_import_template() {
    return Api.get('/rdb/data100/import_template/iri', {
      responseType: "blob",
      headers: {
        ...type_json
      }
    })
  },

  get_rdb_rutting_import_template() {
    return Api.get('/rdb/data100/import_template/rutting', {
      responseType: "blob",
      headers: {
        ...type_json
      }
    })
  },

  import_rdb_data100(import_files, section_id) {
    let apiStr = `/rdb/data100/import?section_id=${section_id}`
    return Api.post(apiStr, import_files, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  /*--------- Logs of bulk importing  ---------------------*/
  get_import_log_all(batch_id) {
    return Api.get(`/rdb/import_log/all?batch_id=${batch_id}`, {
      headers: {
        ...type_json
      }
    })
  },

  get_import_log_by_id(log_id) {
    return Api.get(`/rdb/import_log/${log_id}`, {
      headers: {
        ...type_json
      }
    })
  },
}