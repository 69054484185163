/* 
File: ref_data.js
Description: provides main routes for reference data forms, component of the routes.js
*/
const TreatmentTypes = () => import('@/pages/Treatments/TreatmentTypes.vue')
const TreatmentTypeEditForm = () => import('@/pages/Treatments/TreatmentTypeEditForm.vue')
const TreatmentRates = () => import('@/pages/Treatments/TreatmentRates.vue')
const TreatmentRateEditForm = () => import('@/pages/Treatments/TreatmentRateEditForm.vue')
const TreatmentMatrix = () => import('@/pages/Treatments/TreatmentMatrix.vue')
const TreatmentMatrixEditForm = () => import('@/pages/Treatments/TreatmentMatrixEditForm.vue')

const TreatmentsMenu = [
  /*
     Treatment Typess
  */
  {
    path: 'types',
    name: 'Treatment_types',
    components: { default: TreatmentTypes },
  },
  {
    path: 'types/add',
    name: 'treatment_type_add',
    component: TreatmentTypeEditForm,
    props: { oper: 'add' }
  },
  {
    path: 'types/upd/:treatment_type_id',
    name: 'treatment_type_upd',
    component: TreatmentTypeEditForm,
    props: { oper: 'upd' }
  },

  /*
     Treatment Rates
  */
  {
    path: 'rates',
    name: 'treatment_rates',
    components: { default: TreatmentRates },
  },
  {
    path: 'rates/add',
    name: 'treatment_rate_add',
    component: TreatmentRateEditForm,
    props: { oper: 'add' }
  },
  {
    path: 'rates/upd/:rid',
    name: 'treatment_rate_upd',
    component: TreatmentRateEditForm,
    props: { oper: 'upd' }
  },

  /*
  Treatment Matrix 
  */
  {
    path: 'treatment_matrix',
    name: 'treatment_matrix',
    components: { default: TreatmentMatrix },
  },
  {
    path: 'treatment_matrix/add',
    name: 'Treatment_matrix_add',
    component: TreatmentMatrixEditForm,
    props: { oper: 'add' }
  },
  {
    path: 'treatment_matrix/upd/:treatment_id',
    name: 'Treatment_matrix_upd',
    component: TreatmentMatrixEditForm,
    props: { oper: 'upd' }
  },
]

export default TreatmentsMenu
