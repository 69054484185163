//import Api from '@/api'
const settings = [
    { key: "MaxFileSize", value: 5000 },
    { key: "SomeKey", value: 'Some value' },
    { key: "Another Key", value: 'Another value' }
]

export default {
    state: {
        list: [],
        //   setting: null
    },

    actions: {
        async LOAD_SETTINGS({ commit }) {
//            if (status === 200) {
                commit('SET_SETTINGS_LIST', settings)
/*              } else {
                throw `Load unit list status: ${status}`
              }
*/
        },

        async LOAD_SETTING_BY_NAME({ commit }, settingName) {
            const setting = settings.find((element) => element.key === settingName).value;
            return setting
        },
    },
    mutations: {
        SET_SETTINGS_LIST(state, list) {
            state.list = [...list]
          },
    },
    getters: {}
}
