/*
File: api/users_groups.js
Description: API connections for the async functions for Users and Groups Mgmt.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  /*---------  GROUPS  ---------------------*/
  /** */
  /** get group list
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_group_list() {
    return Api.get(`/groups`, {
      headers: {
        ...type_json
      }
    })
  },
  /** load group by id
   * @param {integer} id // user id
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_group_by_id(id) {
    return Api.get(`/groups/${id}`, {
      headers: {
        ...type_json
      }
    })
  },

  /** load region list
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */

  load_region_list() {
    return Api.get(`/groups/regions`, {
      headers: {
        ...type_json
      }
    })
  },

  /** add group
   * @param {object} group
   * {
      "name_en": "string",
      "name_kg": "string",
      "name_ru": "string",
      "access_level": 0,
      "region_id": 0
}
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */

  add_group(group) {
    return Api.post(`/groups`, group, {
      headers: {
        ...type_json
      }
    })
  },
  /** upd group
   * @param {integer} id
   * @param {object} group
   * {
      "name_en": "string",
      "name_kg": "string",
      "name_ru": "string"
    }
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  upd_group(id, group) {
    return Api.put(`/groups/${id}`, group, {
      headers: {
        ...type_json
      }
    })
  },
  /** del group
   * @param {integer} id
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  del_group(id) {
    return Api.delete(`/groups/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  /*---------  USERS  ---------------------*/
  /** */
  /** get users list
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_users_list() {
    return Api.get(`/users`, {
      headers: {
        ...type_json
      }
    })
  },
  /** get users groups
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_users_groups() {
    return Api.get(`/groups`, {
      headers: {
        ...type_json
      }
    })
  },

  /** load user by id
   * @param {integer} id // user id
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_user_by_id(id) {
    return Api.get(`/users/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  /** add user
   * @param {object} user
   * {
      "email": "string",
      "first_name": "string",
      "last_name": "string",
      "group_id": integer,
      "role": "string"
    }
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  add_user(user) {
    return Api.post(
      `/users`,
      { ...user },
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  /** upd user
   * @param {integer} id
   * @param {object} user
   * {
      "email": "string",
      "first_name": "string",
      "last_name": "string",
      "group_id": integer,
      "role": "string"
    }
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  upd_user(id, user) {
    return Api.put(
      `/users/${id}`,
      { ...user },
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  /** del user
   * @param {integer} id
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  del_user(id) {
    return Api.delete(`/users/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
}