/*
File: api/dropdowns.js
Description: API connections for the async functions for filling dropdown lists.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  // list of regions for dropdown
  load_regions_dropdown_list(lang = 'en') {
    return Api.get(`/rdb/region/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },

  // list of DEU's for dropdown
  load_deu_dropdown_list(lang = 'en', region_id) {
    let apiStr = `/rdb/deu/all?lang=${lang}`
    if (region_id) apiStr += `&region_id=${region_id}`

    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },

  // list of road classes for dropdown
  load_road_classes_dropdown_list(lang = 'en') {
    return Api.get(`/rdb/road/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },

  // list of roads for dropdown
  load_roads_dropdown_list(lang = 'en', region_id) {
    let apiStr = `/rdb/road/list?lang=${lang}`
    if (region_id) apiStr += `&region_id=${region_id}`

    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },

  // list of sections for dropdown
  load_sections_dropdown_list(lang = 'en', region_id, road_id) {   // for dropdown list
    let apiStr = `/rdb/section/list?lang=${lang}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (road_id) apiStr += `&road_id=${road_id}`
    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },

  // list of work categories for dropdown
  load_work_categories_dropdown_list(lang = 'en') {
    return Api.get(`/rdb/work_category/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },

  // list of years for dropdown in budgeting works
  load_works_years_dropdown_list() {
    return Api.get(`/rdb/section_maintenance_plan/years`, {
      headers: {
        ...type_json
      }
    })
  },

  /*--------- Survey years for dropdowns ---------------------*/
  load_years_dropdown_list(lang = 'en') {
    return Api.get(`/rdb/data100/list_survey_years?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },

  load_import_types_dropdown_list(lang = 'en') {
    return Api.get(`/rdb/import_type/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },

}