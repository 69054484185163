/* 
File: deu.js
Description: called from Deu.vue and DeuEditForm.vue, gets DEU data from DB and passes as a list back to the caller.
*/
import Api from '@/api/roadnetwork'

export default {
  state: {
    list: []
  },

  actions: {
    async LOAD_DEU_LIST({ commit }) {
      const lang = global.localStorage["mps-locale"]

      try {
        const res = await Api.load_rdb_deu_list(lang)
        const { status } = res
        if (status === 200) {
          commit('SET_DEU_LIST', res.data)
          return 'success';
        } else {
          throw `Error LOAD_DEU_LIST: ${status}`
        }
      } catch (err) {
        throw `LOAD_DEU_LIST: ${err}`
      }
    },

    async LOAD_DEU_BY_ID({ commit }, deu_id) {
      try {
        const res = await Api.load_rdb_deu_by_id(deu_id)
        const { status } = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_DEU_BY_ID'
        }
      } catch (err) {
        throw `${err.response.data.message}`
      }
    },

    async ADD_DEU({ commit }, newDeu) {
      try {
        const res = await Api.add_rdb_deu(newDeu)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async UPD_DEU({ commit }, payload) {
      try {
        const { id, theDeu } = payload
        const res = await Api.upd_rdb_deu(id, theDeu)
        if (res.status === 200) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async DEL_DEU({ commit }, deu_id) {
      try {
        const res = await Api.del_rdb_deu(deu_id)
        if (res.status === 200) {
          commit('DEL_DEU_FROM_LIST', deu_id)
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },
  },

  mutations: {
    SET_DEU_LIST(state, list) {
      state.list = [...list]
    },
    DEL_DEU_FROM_LIST(state, id) {
      let ind = state.list.findIndex((row) => row.deu_id === id)
      if (ind >= 0) state.list.splice(ind, 1)
    },
  },

  getters: {}
}