/* 
File: settings.js
Description: provides main routes for settings editing forms, component of the routes.js
*/
const Settings = () => import('@/pages/Settings/Settings.vue')
const Units = () => import('@/pages/Settings/Units.vue')
const UnitEditForm = () => import('@/pages/Settings/UnitEditForm.vue')
const Translate = () => import('@/pages/Dashboard/translate.vue')
const TranslateProfileForm = () => import('@/pages/Dashboard/translateProfileForm.vue')

const WorkCategories = () => import('@/pages/Settings/WorkCategories.vue')
const WorkCategoryEditForm = () => import('@/pages/Settings/WorkCategoryEditForm.vue')
const InventoryTypes = () => import('@/pages/Settings/InventoryTypes.vue')
const InventoryTypeEditForm = () => import('@/pages/Settings/InventoryTypeEditForm.vue')
const InventoryEvents = () => import('@/pages/Settings/InventoryEvents.vue')

const ConditionIndexCriteria = () => import('@/pages/Settings/ConditionIndexCriteria.vue')

const imageTest = () => import('@/pages/Map/ImageShowing.vue')

const SettingsMenu = [
  /*
     Settings
  */
  {
    path: 'settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: 'img',
    name: 'img',
    component: imageTest,
  },
  /*
     Units
  */
  {
    path: 'units',
    name: 'Units',
    components: { default: Units },
  },
  {
    path: 'units/add',
    name: 'Unit_add',
    component: UnitEditForm,
    props: { oper: 'add' }
  },
  {
    path: 'units/upd/:unit_id',
    name: 'Unit_upd',
    component: UnitEditForm,
    props: { oper: 'upd' }
  },

    /*
     Condition Index Criteria
  */

     {
      path: 'condition_index_criteria',
      name: 'condition_index_criteria',
      component: ConditionIndexCriteria,
    },

  /*
   Translations
  */

  {
    path: 'translate',
    name: 'Translate',
    components: { default: Translate },
    //    beforeEnter: checkAuthAndAccess
  },
  {
    path: 'translate_add',
    name: 'Translate_add',
    component: TranslateProfileForm,
    //   beforeEnter: checkAuthAndAccess,
    props: { oper: 'add' }
  },
  /*
     Work Categories
  */
  {
    path: 'work_categories',
    name: 'Work_Categories',
    component: WorkCategories,
  },
  {
    path: 'work_categories/add',
    name: 'Work_Category_add',
    component: WorkCategoryEditForm,
    props: { oper: 'add' }
  },
  {
    path: 'work_categories/upd/:work_category_id',
    name: 'Work_Category_upd',
    component: WorkCategoryEditForm,
    props: { oper: 'upd' }
  },
  /*
     Inventory Types
  */
  {
    path: 'inventory_types',
    name: 'Inventory_Types',
    component: InventoryTypes,
  },
  {
    path: 'inventory_types/add',
    name: 'Inventory_Type_add',
    component: InventoryTypeEditForm,
    props: { oper: 'add' }
  },
  {
    path: 'inventory_types/upd/:inventory_type_id',
    name: 'Inventory_Type_upd',
    component: InventoryTypeEditForm,
    props: { oper: 'upd' }
  },
  /*
     Inventory Events
  */
  {
    path: 'inventory_events',
    name: 'Inventory_Events',
    component: InventoryEvents,
  },
]

export default SettingsMenu