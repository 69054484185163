/* 
File: pictures.js
Description: provides routines for pictures edit forms calling API's.
*/
//import Api from '@/api'

export default {
  state: {
    list: [],
    gps_list: [],
    isPicturesOper: false,
    currentPictures: undefined
  },
  actions: {
    async LOAD_PICTURES_LIST({ commit }) {
      commit('SET_PICTURES_OPER', true)
      console.log("Load pictures list");
    },
    async LOAD_GPS_LIST({ commit }) {
      commit('SET_GPS_LIST', [])
      console.log("Load GPS list");
    },
  },
  mutations: {
    SET_GPS_LIST(state, list) {
      state.gps_list = [...list]
    },
    SET_PICTURES_LIST(state, list) {
      state.list = [...list]
    },
    SET_PICTURES_OPER(state, val) {
      state.isPicturesOper = val
    },
    CHANGE_CURRENT_PICTURES(state, id) {
      state.currentPictures = id
    }
  },
  getters: {}
}
