/* 
File: map_component.js
Description: provides routines for map edit forms calling API's.
*/
import Api from '@/api/coords'
import { GeoJsonTemp, GeoJsonFeature } from './geojson_helper'
import { getLegend, getRange, getRangeVal, getRoadClassColor } from './ranges_helper'

const RoadProps = (i1, i2_2, i2_3, i3, i4) => {
  return [
    i1, //"Description",
    {
      type: 'url',
      href: i2_2 + i3, //'',
      link: i2_3
    },
    i3,  // item.section_id,
    i4,  // section_coords
  ]
}

export default {
  state: {
    selection_info: 'map.nothing',
    // move to the params_component.js ???
    strokeWidth: 1,
    styletype: 'point',   //'line' or 'point',
    parameter: 'iri',    // parameter to color on the map,
    parameterValue: '',
    mapExtent: [80347, 4443524, 1027105, 4767255],    //[409766, 4722088, 424910, 4663562]
    roadsGeoJSON: GeoJsonTemp(),
    paramsGeoJSON: GeoJsonTemp()
  },
  data() {
    return {
      coords: [],
      strWdth: 1
    }
  },
  actions: {
    async LOAD_MAP_ROADS_LIST({ state, dispatch }) {
      const res = await Api.load_map_roads_coords()
      const { status, data } = res
      if (status !== 200) {
        return GeoJsonTemp()
      }

      var sectionList
      try { // Get the sections list for filling properties
        await dispatch('LOAD_SECTIONS_LIST', {}).then(() => {
          sectionList = this._modules.root.state.Sections.list
        })
      } catch (err) {
        throw `LOAD_MAP_ROADS_LIST err: ${err}`
      }

      //const passport_url = 'road-passport/'
      const passport_url = 'settings/map_test?section_id='

      const gjSource = GeoJsonTemp()
      data.forEach((item, index) => {
        /***/
        var road_description, section_description, road_class
        const ind = sectionList.findIndex((row) => row.section_id === item.section_id)
        if (ind >= 0) {
          road_description = sectionList.at(ind).road
          section_description = sectionList.at(ind).section_description
          road_class = sectionList.at(ind).road_key[0]
        } else {
          // Section not found - might not has been entered in the DB
          road_description = "Unpaved road"    //this.$t("road_network.unpaved")
          section_description = "Details on this section might not has been entered in the database"
        }
        /***/

        /* Previous API version was like "coords": ["74.70091093182309,43.02342945747173","74.70027653766648,43.02264435892914"]
        let section_coords = []
        item.coords.forEach((single_coord) => {
          section_coords.push(single_coord.split(","))
        }) */
        //const section_coords = JSON.parse(item.coords)
        const section_coords = item.coords
        const feature = GeoJsonFeature()
        // Set color depending on the road class
        switch (road_class) {  //(item.road_class) {
          case 'Э':
            feature.properties.style.stroke.color = '#CC3300'
            break
          case 'М':
            feature.properties.style.stroke.color = '#ffcc00'
            break
          case 'Ж':
            feature.properties.style.stroke.color = '#339900'
            break
        }
        feature.properties.style.stroke.width = state.strokeWidth   //2 //4

        switch (state.styletype) {
          case "point":
            feature.geometry.type = 'MultiPoint'
            section_coords.forEach((single_coord) => {
              feature.geometry.coordinates.push(single_coord)
            })
            feature.properties.style.type = 'circle'
            //feature.properties.style.fill = { color: feature.properties.style.stroke.color }
            break
          case "line":
            feature.geometry.type = 'MultiLineString'
            feature.geometry.coordinates.push(section_coords)
            break
        }

        feature.properties.localisation_keys = ['road_network.road', 'road_network.section', '']  //'link']
        const road_props = [
          road_description,      //item.road_description,
          section_description,   //item.section_description,
          /*{
            type: 'url',
            href: `/${passport_url + item.section_id}`,
            link: 'road_network.section_details'
          },*/
          item.section_id
        ]
        feature.properties.data.push(road_props)
        gjSource.features.push(feature)
      })
      return gjSource
    },

    async LOAD_ROADS_COORDS({ commit, dispatch, state }, payload) {
      const { region_id, road_id, deu_id, section_id, zoom, start_x, start_y, end_x, end_y } = payload
      let { data_100_column_name } = payload
      if (data_100_column_name === 'road_class') {
        // Re-create payload object without this param
        // did not work Object.defineProperty(payload, 'data_100_column_name', {value: undefined})
        payload = Object.defineProperties({}, {
          region_id: { value: region_id },
          road_id: { value: road_id, },
          deu_id: { deu_id },
          section_id: { value: section_id, },
          zoom: { value: zoom },
          start_x: { value: start_x },
          start_y: { value: start_y },
          end_x: { value: end_x },
          end_y: { value: end_y },
        })
        data_100_column_name = null
      }
      console.log('LOAD_ROADS_COORDS', payload)
      try {
        await Api.load_windowed_map_coords(payload).then((res) => {
          if (res.status == 200) {
            //commit('SET_MAPEXTENT', [payload.start_x, payload.start_y, payload.end_x, payload.end_y]) // initial window coords
            this.coords = res.data
            this.strWdth = state.strokeWidth + (zoom - 7) / 2 + (state.styletype === "line") ? 2 : 0
            //if ((region_id || road_id) && data_100_column_name)
            if (data_100_column_name)
              dispatch('PARSE_COORDS_2', data_100_column_name)
            else
              dispatch('PARSE_COORDS', (region_id || road_id || deu_id || section_id) ? "ra" : null)
          }
        })
      } catch (err) {
        throw `LOAD_ROADS_COORDS err: ${err}`
      }
    },

    async PARSE_COORDS({ state, commit }, parameter) {
      //const valuesRange = getRange('roads')
      //const colorsRange = getLegend(parameter)

      const gjSource = GeoJsonTemp()
      const newMapExtent = []
      let firstCoord = true
      this.coords.forEach((item) => {
        const col = getRoadClassColor(item.road_class)

        const feature = GeoJsonFeature('MultiPoint', col, this.strWdth)
        const section_coords = JSON.parse(item.coords)          //item.coords

        var data100_id
        section_coords.forEach((single_coord) => {
          data100_id = single_coord.shift();
          const val = single_coord.pop();

          /*        // Определение цвета, если параметр задан
                  if (parameter) {
                    const color = colorsRange[getRangeVal(val, valuesRange)].colour;
                    feature.properties.style.stroke.color = color;
                  }
        */
          if (state.styletype === "point") {
            feature.geometry.coordinates.push([single_coord[0], single_coord[1]])
            //feature.properties.style.type = 'circle';
          }

          if (parameter) {
            if (firstCoord) {
              newMapExtent.push(single_coord[0])
              newMapExtent.push(single_coord[1])
              newMapExtent.push(single_coord[0])
              newMapExtent.push(single_coord[1])
              firstCoord = false
            } else {
              if (newMapExtent[0] > single_coord[0]) newMapExtent[0] = single_coord[0]
              if (newMapExtent[1] > single_coord[1]) newMapExtent[1] = single_coord[1]
              if (newMapExtent[2] < single_coord[0]) newMapExtent[2] = single_coord[0]
              if (newMapExtent[3] < single_coord[1]) newMapExtent[3] = single_coord[1]
            }
          }

        });

        if (state.styletype === "line") {
          feature.geometry.type = 'MultiLineString';
          feature.geometry.coordinates.push(section_coords);
        }
        const road_props = RoadProps("Sect", "/inventory_data/sections/upd/", 'route.section_upd', item.section_id, data100_id)
        feature.properties.data.push(road_props)
        gjSource.features.push(feature)
      })
      commit((!parameter) ? 'SET_ROADS_GEO_JSON' : 'SET_PARAMS_GEO_JSON', gjSource)
      if (parameter) { // Adjustment
        newMapExtent[0] -= 200
        newMapExtent[1] -= 200
        newMapExtent[2] += 200
        newMapExtent[3] += 200
        commit('SET_MAPEXTENT', newMapExtent)
      }
    },

    async PARSE_COORDS_2({ commit }, parameter) {
      const valuesRange = getRange(parameter)
      const colorsRange = getLegend(parameter)
console.log('parameter, valuesRange, colorsRange', parameter, valuesRange, colorsRange)
      const gjSource = GeoJsonTemp()
      this.coords.forEach((item, index) => {
        const section_coords = JSON.parse(item.coords)
        section_coords.forEach((single_coord) => {
          const val = single_coord.pop()
          const color = colorsRange[getRangeVal(val, valuesRange)].colour
          const feature = GeoJsonFeature("Point", color, this.strWdth)
          const data100_id = single_coord.shift()

          feature.geometry.coordinates.push(single_coord[0], single_coord[1])
          const road_props = RoadProps("Sect", "/inventory_data/sections/upd/", 'route.section_upd', item.section_id, data100_id)
          //const road_props = [data100_id]
          feature.properties.data.push(road_props)
          gjSource.features.push(feature)
        })
      })
      commit('SET_PARAMS_GEO_JSON', gjSource)
    }
  },
  computed: {
    sectionList() {
      return this.$store.state.Sections.list
    },
  },
  mutations: {
    SET_ROADS_GEO_JSON(state, geoJSON) {
      state.roadsGeoJSON = geoJSON
    },
    SET_PARAMS_GEO_JSON(state, geoJSON) {
      state.paramsGeoJSON = geoJSON
    },
    SET_ROAD_PARAMETER(state, parameter) {
      state.parameter = parameter
    },
    //move to the params?
    SET_STROKE_WIDTH(state, stroke) {
      state.strokeWidth = stroke
    },
    SET_STYLE_TYPE(state, styletype) {
      state.styletype = styletype
    },
    SET_SELECTION_INFO(state, value, concat = false) {
      if (concat) state.selection_info += value; else state.selection_info = value
    },
    SET_MAPEXTENT(state, newMapExtent) {
      state.mapExtent = newMapExtent
    },
  },
  getters: {}
}
