/* 
File: api/reference_data.js
Description: API connections for the async functions for Units and Work Categories.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  load_rdb_lookup(lang = 'en', lookup_type) {
    return Api.get(`/rdb_lookup/all?lang=${lang}&lookup_type=${lookup_type}`, {
      headers: {
        ...type_json
      }
    })
  },

  /*---------  Units  ---------------------*/
  load_units(lang = 'en') {
    return Api.get(`/unit/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  load_unit_by_id(id) {
    return Api.get(`/unit/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_unit(unit) {
    return Api.post(`/unit`, unit, {
      headers: {
        ...type_json
      }
    })
  },
  upd_unit(id, unit) {
    return Api.put(`/unit/${id}`, unit, {
      headers: {
        ...type_json
      }
    })
  },

  /*--------- Work Categories  ---------------------*/
  load_work_categories(lang = 'en') {
    return Api.get(`/rdb/work_category/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  load_work_category_by_id(id, lang = 'en') {
    return Api.get(`/rdb/work_category/${id}?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_work_category(work_category) {
    return Api.post(`/rdb/work_category`, work_category, {
      headers: {
        ...type_json
      }
    })
  },
  upd_work_category(id, work_category) {
    return Api.put(`/rdb/work_category/${id}`, work_category, {
      headers: {
        ...type_json
      }
    })
  },
}