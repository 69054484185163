/*
File: api/sections.js
Description: API connections for the async functions for Sections and Section geometry.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  /*--------- Sections  ---------------------*/
  load_sections_all(lang = 'en', region_id, road_id, deu_id) {
    let apiStr = `/rdb/section/all?lang=${lang}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (road_id) apiStr += `&road_id=${road_id}`
    if (deu_id) apiStr += `&deu_id=${deu_id}`
    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },
  load_section_by_id(id, lang = 'en') {
    return Api.get(`/rdb/section/${id}?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  get_region_road_id_from_section(id) {
    return Api.get(`/rdb/section/region_road/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_section(section) {
    return Api.post(`/rdb/section`, section, {
      headers: {
        ...type_json
      }
    })
  },
  upd_section(id, section) {
    return Api.put(`/rdb/section/${id}`, section, {
      headers: {
        ...type_json
      }
    })
  },
  del_section(section_id) {
    return Api.delete(`/rdb/section/${section_id}`, {
      headers: {
        ...type_json
      }
    })
  },

  /*--------- Section geometry  ---------------------*/
  load_rdb_section_geometry_all(lang = 'en', section_id) {
    let apiStr = `/rdb/section_geometry/all?lang=${lang}&section_id=${section_id}`
    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },
  load_rdb_section_geometry_by_id(lang = 'en', geometry_id) {
    return Api.get(`/rdb/section_geometry/${geometry_id}?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_rdb_section_geometry(geometry) {
    return Api.post(`/rdb/section_geometry`, geometry, {
      headers: {
        ...type_json
      }
    })
  },
  upd_rdb_section_geometry(geometry_id, geometry) {
    return Api.put(`/rdb/section_geometry/${geometry_id}`, geometry, {
      headers: {
        ...type_json
      }
    })
  },
  del_rdb_section_geometry(geometry_id) {
    return Api.delete(`/rdb/section_geometry/${geometry_id}`, {
      headers: {
        ...type_json
      }
    })
  },
}