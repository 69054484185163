/* 
File: api/homo_sections.js
Description: API connections for the async functions for the homogeneous sections list.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {

  /*---------  Work list ---------------------*/
  load_hs_lists(lang = 'en', year, is_approved) {
    let apiStr = `/homogeneous_section_list/all?lang=${lang}`
    if (year) apiStr += `&year=${year}`
    if (is_approved!=null) apiStr += `&is_approved=${is_approved}`   // !==null as we accept 0 as the value
    return Api.get(apiStr, {
      headers: {
        ...type_json
      }
    })
  },

  get_hs_list_by_id(lang = 'en', homogeneous_section_list_id) {
    return Api.get(`/homogeneous_section_list/${homogeneous_section_list_id}?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },

  load_all_hs(homogeneous_section_list_id) {
    return Api.get(`/homogeneous_section_list/all_hs/${homogeneous_section_list_id}`, {
      headers: {
        ...type_json
      }
    })
  },

  generate_hs(year_from, year_to, min_hs_length, condition_index_threshold) {
    let apiStr = '/homogeneous_section_list/generate_hs/?'
    if (year_from!=null) apiStr += `year_from=${year_from}`
    if (year_to!=null) apiStr += `&year_to=${year_to}`
    if (min_hs_length!=null) apiStr += `&min_hs_length=${min_hs_length}`
    if (condition_index_threshold!=null) apiStr += `&condition_index_threshold=${condition_index_threshold}`

    return Api.post(apiStr, {
      headers: {
        ...type_json
      }
    })
  },

  del_hs_list(homogeneous_section_list_id) {
    return Api.delete(`/homogeneous_section_list/${homogeneous_section_list_id}`, {
      headers: {
        ...type_json
      }
    })
  },

  approve_hs_list(homogeneous_section_list_id) {
    return Api.put(`/homogeneous_section_list/approve/${homogeneous_section_list_id}`, {
        headers: {
        ...type_json
      }
    })
  },
  cancel_hs_list(homogeneous_section_list_id) { 
    return Api.put(`/homogeneous_section_list/cancel_approval/${homogeneous_section_list_id}`, {
        headers: {
        ...type_json
      }
    })
  }
}