/* 
File: api/treatments.js
Description: API connections for the async functions for the Treatments and Treatment Matrix.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  /*---------  Treatment Types  ---------------------*/
  load_treatment_types(lang = 'en') {
    return Api.get(`/treatment_type/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  load_treatment_type_by_id(id) {
    return Api.get(`/treatment_type/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_treatment_type(treatment_type) {
    return Api.post(`/treatment_type`, treatment_type, {
      headers: {
        ...type_json
      }
    })
  },
  upd_treatment_type(id, treatment_type) {
    return Api.put(`/treatment_type/${id}`, treatment_type, {
      headers: {
        ...type_json
      }
    })
  },

  /*---------  Rates  ---------------------*/
  load_treatment_rates(lang) {
    return Api.get(`/treatment_rate/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  load_treatment_rate_by_id(id) {
    return Api.get(`/treatment_rate/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  add_treatment_rate(treatmentRate) {
    return Api.post(`/treatment_rate`, treatmentRate, {
      headers: {
        ...type_json
      }
    })
  },

  /*---------  Treatment matrix ---------------------*/
  load_treatment_matrix(lang = 'en') {
    return Api.get(`/treatment_matrix/all?lang=${lang}`, {
      headers: {
        ...type_json
      }
    })
  },

  load_treatment_matrix_item(id) {
    return Api.get(`/treatment_matrix/${id}`, {
      headers: {
        ...type_json
      }
    })
  },

  add_treatment_matrix_item(item) {
    return Api.post('/treatment_matrix', item, {
      headers: {
        ...type_json
      }
    })
  },

  upd_treatment_matrix_item(id, item) {
    return Api.put(`/treatment_matrix/${id}`, item, {
      headers: {
        ...type_json
      }
    })
  },

  del_treatment_matrix_item(id) {
    return Api.post(`/treatment_matrix/${id}`, id, {
      headers: {
        ...type_json
      }
    })
  },

  
}