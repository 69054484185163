/*
File: api/bridges_tonnels.js
Description: API connections for the async functions for Bridges & Tonnels.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
  /*  ---------  Bridges&Tonnels  ---------------------*/
  /** Table data
   * @return {Promise<*> {}} - 200 Response
   * @throws Error
   */
  load_bridges_table() {
    return Api.get(`/bridges/table`, {
      headers: {
        ...type_json
      }
    })
  },
  /** Bridges coords
   * @return {Promise<*> {}} - 200 Response
   * @throws Error
   */
  load_bridges_coords() {
    return Api.get(`/bridges/coords`, {
      headers: {
        ...type_json
      }
    })
  },
  /** tunnels coords
   * @return {Promise<*> {}} - 200 Response
   * @throws Error
   */
  load_tunnels_coords() {
    return Api.get(`/tunnels/coords`, {
      headers: {
        ...type_json
      }
    })
  },
  /** Bridge details
   * @param {string} uuid
   * @return {Promise<*> {}} - 200 Response
   * @throws Error
   */
  load_bridge_details(uuid) {
    return Api.get(`/bridges/${uuid}`, {
      headers: {
        ...type_json
      }
    })
  },
}