/* 
File: units.js
Description: called from Units.vue and UnitEditForm.vue, 
gets measurement units data from DB and passes as a list back to the caller.
*/
import Api from '@/api/reference_data'

export default {
  state: {
    list: [],
  },
  actions: {
    async LOAD_UNIT_LIST({commit}, show_all = false) {
      const lang = global.localStorage["mps-locale"]

      try {
        const res = await Api.load_units(lang)
        const {status} = res
        if (status === 200) {
          if (show_all) res.data.splice(0, 0, { id: 0, description: '' })
          commit('SET_UNIT_LIST', res.data)
        } else {
          throw `Load unit list status: ${status}`
        }
      } catch (err) {
        throw `Load unit list: ${err}`
      }
    },
    async LOAD_UNIT_BY_ID({commit}, id) {
      try {
        const res = await Api.load_unit_by_id(id)
        const {status} = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_UNIT_BY_ID'
        }
      } catch (err) {
        throw `${err.response.data.message}`
      }
    },
    async ADD_UNIT({commit}, unit) {
      try {
        const res = await Api.add_unit(unit)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },
    async UPD_UNIT({commit}, payload) {
      try {
        const {id, theUnit} = payload
        const res = await Api.upd_unit(id, theUnit)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    }
  },
  mutations: {
    SET_UNIT_LIST(state, list) {
      state.list = [...list]
    },
  },
}