/* 
File: reports.js
Description: Async functions for  Reports .
*/
import Api from '@/api/reports'

export default {
  state: {
    report_1_data: [],    // administrative_setup
    report_2_data: [],    // list_of_roads
    report_3_data: [],    // yearwise_report
    report_4_data: [],    // length_by_traffic
    report_5_data: [],    // road_way_details
    report_6_data: [],    // road_sections
    report_7_data: [],    // road_condition_summary
    report_8_data: [],    // road_condition_inxex
    report_9_data: [],    // yearly_condition_inxex
    report_10_data: [],   // section_wise_aadt
    report_11_data: [],   // total_elevation_zones
    report_12_data: [],   // road_length_by_elevation_zones
  },

  actions: {
    async REPORT_1_ADMINISTRATIVE_SETUP({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { inserted_date, region_id, road_class } = values
      try {
        const res = await Api.report_1_administrative_setup(lang, inserted_date, region_id)  //, road_class)
        if (res.status === 200) {
          commit('SET_REPORT_1', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_2_ROAD_INFO({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { inserted_date, region_id, deu_id, road_class } = values
      try {
        const res = await Api.report_2_roads_info(lang, inserted_date, region_id, deu_id, road_class)
        if (res.status === 200) {
          commit('SET_REPORT_2', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_3_YEARWISE_LENGTH({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { start_year, end_year, region_id, road_class, deu_id } = values
      try {
        const res = await Api.report_3_year_wise_road_length(lang, start_year, end_year, region_id, road_class, deu_id)
        if (res.status === 200) {
          commit('SET_REPORT_3', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_4_LENGTH_BY_TRAFFIC({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { start_year, end_year, region_id, road_class, deu_id } = values
      try {
        const res = await Api.report_4_road_length_by_traffic(lang, start_year, end_year, region_id, road_class, deu_id)
        if (res.status === 200) {
          commit('SET_REPORT_4', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_5_ROADWAY_DETAILS({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { region_id, road_class, deu_id, selected_date } = values
      try {
        const res = await Api.report_5_road_way_details(lang, region_id, road_class, deu_id, selected_date)
        if (res.status === 200) {
          commit('SET_REPORT_5', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_6_ROAD_SECTIONS({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { region_id, road_class, deu_id, selected_date } = values
      try {
        const res = await Api.report_6_sections(lang, region_id, road_class, deu_id, selected_date)
        if (res.status === 200) {
          commit('SET_REPORT_6', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_7_ROAD_CONDITION_DATA({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { region_id, deu_id, road_id, section_id, start_date, end_date } = values
      try {
        const { status, data } = await Api.report_7_road_condition_summary(lang, region_id, deu_id, road_id, section_id, start_date, end_date)
        if (status === 200) {
          const res = data.map(item => ({
            ...item,
            distance: item.survey_end_distance_km - item.survey_start_distance_km
          }))
          commit('SET_REPORT_7', res)
          return 'success'
        } else {
          throw status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_8_ROAD_CONDITION_INDEX({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { region_id, survey_year } = values
      try {
        const res = await Api.report_8_region_wise_condition(lang, region_id, survey_year)
        if (res.status === 200) {
          commit('SET_REPORT_8', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_9_YEARLY_CONDITION_INDEX({ commit }, values) {
      const lang = global.localStorage["mps-locale"]
      const { region_id, deu_id, road_id, section_id, start_year, end_year } = values
      try {
        const res = await Api.report_9_yearly_condition(lang, region_id, deu_id, road_id, section_id, start_year, end_year)
        if (res.status === 200) {
          commit('SET_REPORT_9', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_10_SECTION_WISE_AADT({ commit }) {
      const lang = global.localStorage["mps-locale"]
      //const { selected_year, region_id, deu_id, road_id } = values
      try {
        const res = await Api.report_10_section_wise_aadt(lang) //, selected_year, selected_year, region_id, deu_id, road_id)
        if (res.status === 200) {
          commit('SET_REPORT_10', res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_11_TOTAL_ELEVATION_ZONES({ commit }) {
      const lang = global.localStorage["mps-locale"]
      try {
        const { status, data } = await Api.report_11_total_elevation_zones(lang)
        if (status === 200) {
          // remove next 4 lines after implementing via API
          const res = data.map(item => ({
            ...item,
            region_description: item[lang === 'ru' ? 'region_description' : 'region_description_en']
          }))

          commit('SET_REPORT_11', res)  //res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async REPORT_12_LENGTH_BY_ELEVATION_ZONES({ commit }) {
      const lang = global.localStorage["mps-locale"]
      try {
        const { status, data } = await Api.report_12_road_length_by_elevation_zones(lang)
        if (status === 200) {
          // remove next 4 lines after implementing via API
          const res = data.map(item => ({
            ...item,
            region_description: item[lang === 'ru' ? 'region_description' : 'region_description_en']
          }))

          commit('SET_REPORT_12', res)  //res.data)
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },
  },

  mutations: {
    SET_REPORT_1(state, list) {
      state.report_1_data = [...list]
    },
    SET_REPORT_2(state, list) {
      state.report_2_data = [...list]
    },
    SET_REPORT_3(state, list) {
      state.report_3_data = [...list]
    },
    SET_REPORT_4(state, list) {
      state.report_4_data = [...list]
    },
    SET_REPORT_5(state, list) {
      state.report_5_data = [...list]
    },
    SET_REPORT_6(state, list) {
      state.report_6_data = [...list]
    },
    SET_REPORT_7(state, list) {
      state.report_7_data = [...list]
    },
    SET_REPORT_8(state, list) {
      state.report_8_data = [...list]
    },
    SET_REPORT_9(state, list) {
      state.report_9_data = [...list]
    },
    SET_REPORT_10(state, list) {
      state.report_10_data = [...list]
    },
    SET_REPORT_11(state, list) {
      state.report_11_data = [...list]
    },
    SET_REPORT_12(state, list) {
      state.report_12_data = [...list]
    }
  },

  getters: {
    report_1_total: (state) => {
      const fields = [
        'length_km_road_class_m',
        'length_km_road_class_em',
        'length_km_road_class_zh',
        'road_class_none'
      ];

      const res = fields.reduce((totals, field) => {
        totals[field] = state.report_1_data.reduce((acc, currVal) => acc + (currVal[field] || 0), 0);
        return totals;
      }, {});
      res['total'] = state.report_1_data.length
      return res
    },

    report_2_total: (state) => {
      return state.report_2_data.reduce((acc, currValue) => acc + currValue.length_km, 0,)
    },

    report_6_total: (state) => {
      return state.report_6_data.reduce((acc, currValue) => acc + currValue.length_km, 0,)
    },

    report_7_total: (state) => {
      return state.report_7_data.reduce((acc, currValue) => acc + currValue.distance, 0,)
    },

    roadsInReport7: (state) => {
      const uniqueIds = new Set();
      const res = state.report_7_data.reduce((acc, item) => {
        if (!uniqueIds.has(item.road_id)) {
          uniqueIds.add(item.road_id);
          acc.push({ id: item.road_id, name: item.road_description });
        }
        return acc;
      }, [])
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))

      res.unshift({ id: 0, name: '' })   // Add null value to the beginnig
      return res
    },

    report_8_total: (state) => {
      return state.report_8_data.reduce((acc, currValue) => acc + currValue.length_km, 0,)
    },

    report10_notNull: (state) => {
      const filteredRows = state.report_10_data.filter(row => {
        const nonEmptyFields = [row.aadt_a1_spc, row.aadt_b1_mb, row.aadt_b2_l2tr, row.aadt_b3_tractors,
        row.aadt_c1_m2tr, row.aadt_c2_h3tr, row.aadt_d1_buses, row.aadt_d2_truck_tr, row.aadt_d3_ar4tr_tr]
          .filter(field => field !== null && field !== undefined && field !== 0);
        return nonEmptyFields.length >= 9;
      });

      return filteredRows
    },

    report10_filtered: (state, getters) => (region_id, road_id, year) => {
      return getters.report10_notNull.filter(el =>
        (!region_id || el.region_id === region_id) &&  // Проверка на регион (если передан)
        (!road_id || el.road_id === road_id) &&  // Проверка на дорогу (если передана)
        (!year || el.survey_year === year)           // Проверка на год (если передан)
      )
    },

    regionsInReport10: (state, getters) => {
      const uniqueIds = new Set();
      const res = getters.report10_notNull.reduce((acc, item) => {
        if (!uniqueIds.has(item.region_id)) {
          uniqueIds.add(item.region_id);
          acc.push({ id: item.region_id, name: item.region_description });
        }
        return acc;
      }, [])
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))

      res.unshift({ id: 0, name: '' })   // Add null value to the beginnig
      return res
    },

    roadsInReport10: (state, getters) => (region_id) => {
      const uniqueIds = new Set();
      const res = getters.report10_filtered(region_id).reduce((acc, item) => {
        if (!uniqueIds.has(item.road_id)) {
          uniqueIds.add(item.road_id);
          acc.push({ id: item.road_id, name: item.road_description });
        }
        return acc;
      }, [])
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))

      res.unshift({ id: 0, name: '' })   // Add null value to the beginnig
      return res
    },

    yearsInReport10: (state, getters) => {
      // Get all unique years in the list, order and return as an  array of objects for BaseDropdown
      return [...new Set(getters.report10_notNull.reduce((acc, item) => {
        if (item.survey_year !== null) acc.push(item.survey_year)
        return acc
      }, []))].sort((a, b) => a - b).map(year => ({ year }));
    },

    report_11_total: (state) => {
      const zoneTotals = {
        zone_1: 0,
        zone_2: 0,
        zone_3: 0,
        zone_4: 0,
        zone_5: 0,
        zone_6: 0,
        total: 0
      };

      return state.report_11_data.reduce((totals, row) => {
        Object.keys(totals).forEach(zone => { totals[zone] += row[zone] || 0 });
        return totals;
      }, zoneTotals);
    },

    report12_filtered: (state) => (region_id, road_class) => {
      return state.report_12_data.filter(el =>
        (!region_id || el.region_id === region_id) &&
        (!road_class || el.road_class === road_class)
      )
    },

    report_12_total: (state, getters) => (region_id, road_class) => {
      const zoneTotals = {
        zone_1: 0,
        zone_2: 0,
        zone_3: 0,
        zone_4: 0,
        zone_5: 0,
        zone_6: 0,
        total: 0
      };

      return getters.report12_filtered(region_id, road_class).reduce((totals, row) => {
        Object.keys(totals).forEach(zone => { totals[zone] += row[zone] || 0 });
        return totals;
      }, zoneTotals);
    },
  }
}