/*
File: api/image_showing.js
Description: API connections for the async functions for Images download.
*/
import Api_ref from '@/api'

const Api = Api_ref.props.Api
const type_json = Api_ref.props.type_json

export default {
/*---------  Inventory Types  ---------------------*/
load_image_file(fileName) {
    return Api.get(`/refdata/image/${fileName}`, {
      headers: {
        ...type_json
      }
    })
  },
}