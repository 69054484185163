// history.js
// routines to maintain history for returning back to the same state after editing
export default {
  data() {
    return {
      history_for: null,
      history_data: null,
      history_use: false
    }
  },

  actions: {
    LOAD_HISTORY({}) {
      // return JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('history')))))
      //const res = (!this.history_data) ? '' : JSON.parse(decodeURIComponent(escape(atob(this.history_data))))
      return {for: this.history_for, data: this.history_data, use: this.history_use}
    },
    async SAVE_HISTORY({}, payload) {
      //localStorage.setItem('history', btoa(unescape(encodeURIComponent(JSON.stringify(hist)))))
      this.history_for = payload.for
      this.history_data = payload.data
      this.history_use = false
    },
    async SET_USE_HISTORY({}) {
      //localStorage.setItem('history_for', route)
      this.history_use = true
    },
    async CLEAR_HISTORY() {
      //localStorage.setItem('history_for', '')
      this.history_for = null
      this.history_data = null
      this.history_use = false
    },
  },

  mutations: {
  },
  getters: {}
}
